import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { GoogleAuthResponse } from "../../components/organisms/GoogleAuth";

import { gapi } from 'gapi-script';

import { GOOGLE_AUTH_CLIENT_ID } from '../../constants';
import { AuthUpdateTokenAction, AuthUserAuthenticatedAction, AuthUserLogoutAction, ReducerAuthStateSessionData, setCurrentUserData } from "../features/auth";
import { store } from "../store";
import { getUsersByEmailQuery } from "../../graphql/queries/queryUsersConnections";
import { client } from "../../utils/graphQLClient";
import { ConsoleUI } from "../../utils/notification.service";



class AuthService {
	constructor() {
		gapi.load('client:auth2', () => {
			gapi.client.init({
				clientId: GOOGLE_AUTH_CLIENT_ID,
				scope: 'email',
			});
		});

		this.checkCachedData();
	}

	authSessionDataCheckerInterval: NodeJS.Timer | null = null;

	async checkCurrentStoredUser() {
		const currentTokenEmail = window.sessionStorage.getItem('authUserEmail');
		if (!currentTokenEmail) {
			setCurrentUserData(null);
		} else if (store.getState().auth.currentUser?.email !== currentTokenEmail) {
			const currentUserData = await ConsoleUI.monitor(client.query({ fetchPolicy: 'network-only', query: getUsersByEmailQuery, variables: {
				email: currentTokenEmail,
			}}));
			const currentUser = (currentUserData?.data?.usersConnection?.edges || [])[0].node || null;

			store.dispatch(
				setCurrentUserData(currentUser)
			);
		}
	}

	async checkCachedData() {
		await store.dispatch(AuthUpdateTokenAction(this.GoogleAuthSessionStorageData()));
		const state = store.getState().auth;
		if (state.data === null) {
			if (typeof this.authSessionDataCheckerInterval === "number") {
				clearInterval(this.authSessionDataCheckerInterval);
				this.authSessionDataCheckerInterval = setInterval(() => {
					if ((state.data || !state.sessionData.tokenId) && this.authSessionDataCheckerInterval) {
						clearInterval(this.authSessionDataCheckerInterval);
						this.authSessionDataCheckerInterval = null;
					} else {
						store.dispatch(
							AuthUpdateTokenAction(this.GoogleAuthSessionStorageData())
						);

						this.checkCurrentStoredUser();
					}
				}, 2000);
			// } else {
			// 	this.checkCurrentStoredUser();
			}
		}
	}

	refreshGoogleTokenSetup(res: GoogleLoginResponse | GoogleAuthResponse): void {
		let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

		const refreshToken = async () => {
			try {
				const newAuthRes = await (res as GoogleLoginResponse).reloadAuthResponse();

				refreshTiming = ( newAuthRes.expires_in || 3600 - 5 * 60 ) * 1000;

				// TODO update stored new auth token newAuthRes.id_token
				window.sessionStorage.setItem('accessToken', newAuthRes.access_token);
				window.sessionStorage.setItem('accessTokenId', newAuthRes.id_token);
				window.sessionStorage.setItem('accessTokenExpire', `${newAuthRes.expires_in}`);
				window.sessionStorage.setItem('authUserEmail', res.profileObj.email);
				window.sessionStorage.setItem('authUserImage', res.profileObj.imageUrl);
				window.sessionStorage.setItem('authUserName', res.profileObj.name);

				AuthUpdateTokenAction({
					expire: newAuthRes.expires_in,
					token: newAuthRes.access_token,
					tokenId: newAuthRes.id_token,
					email: res.profileObj.email,
					image: res.profileObj.imageUrl,
					name : res.profileObj.name,
				});

				this.checkCurrentStoredUser();

				setTimeout(refreshToken, refreshTiming);
			} catch (err) {
				console.warn(err);
			}
		}

		if ('reloadAuthResponse' in res) {
			setTimeout(refreshToken, refreshTiming);
		}
	}

	GoogleAuthSessionStorageData(): ReducerAuthStateSessionData {
		return ({
			token: window.sessionStorage.getItem('accessToken') || '',
			tokenId: window.sessionStorage.getItem('accessTokenId') || '',
			expire: parseInt(window.sessionStorage.getItem('accessTokenExpire') || '', 10) || 0,
			email: window.sessionStorage.getItem('authUserEmail') || '',
			image: window.sessionStorage.getItem('authUserImage') || '',
			name: window.sessionStorage.getItem('authUserName') || '',
		})
	}

	GoogleAuthSessionStorageClear() {
		window.sessionStorage.removeItem('accessToken');
		window.sessionStorage.removeItem('accessTokenId');
		window.sessionStorage.removeItem('accessTokenExpire');
		window.sessionStorage.removeItem('authUserEmail');
		window.sessionStorage.removeItem('authUserImage');
		window.sessionStorage.removeItem('authUserName');

		store.dispatch(AuthUserLogoutAction());

		this.checkCurrentStoredUser();
	}

	onGoogleAuthSuccessHandler(res: GoogleAuthResponse | GoogleLoginResponse | GoogleLoginResponseOffline, cb?: () => void) {
		if ('accessToken' in res) {
			window.sessionStorage.setItem('accessToken', res.accessToken);
			window.sessionStorage.setItem('accessTokenId', res.tokenObj.id_token);
			window.sessionStorage.setItem('accessTokenExpire', `${res.tokenObj.expires_in}`);
			window.sessionStorage.setItem('authUserEmail', res.profileObj.email);
			window.sessionStorage.setItem('authUserImage', res.profileObj.imageUrl);
			window.sessionStorage.setItem('authUserName', res.profileObj.name);

			if (res.tokenObj && res.profileObj) {
				store.dispatch(AuthUserAuthenticatedAction(res));
				store.dispatch(AuthUpdateTokenAction(this.GoogleAuthSessionStorageData()));

				this.checkCurrentStoredUser();
			}
		} else {
			this.GoogleAuthSessionStorageClear();
		}

		if ('tokenObj' in res) {
			this.refreshGoogleTokenSetup(res);
		}

		if (cb) cb();
	}

	onGoogleLogoutSuccessHandler() {
		this.GoogleAuthSessionStorageClear();
	}

	onGoogleAuthFailureHandler(err: Error) {
		this.GoogleAuthSessionStorageClear();
		console.error(err);
	}
}

export default AuthService;
