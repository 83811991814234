import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const renderDeleteConfirmDialog = (props: { open: boolean, handleClose: () => void, handleOk: () => void, handleCancel: () => void }):JSX.Element => {
	return (
	  <div>
		<Dialog
		  open={props.open}
		  onClose={props.handleClose}
		  aria-labelledby="alert-dialog-title"
		  aria-describedby="alert-dialog-description"
		>
		  <DialogTitle id="alert-dialog-title">
			{"Are you sure that you want to delete the selected task?"}
		  </DialogTitle>
		  <DialogContent>
			<DialogContentText id="alert-dialog-description">
			  After deletion you can not restore the task.
			  <br />
			  The users assigned to current will be unasigned
			</DialogContentText>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={props.handleCancel}>Disagree</Button>
			<Button onClick={props.handleOk} autoFocus>
			  Agree
			</Button>
		  </DialogActions>
		</Dialog>
	  </div>
	);
  }
