import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ApolloProvider } from "@apollo/client";

import { client } from './utils/graphQLClient';

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import {
    AppPages
} from './components/pages';

import { Provider } from 'react-redux';

import { store } from './store/store';
import GoogleAuthLogin from './components/organisms/GoogleAuth';

const root = document.getElementById('root');

const RootApp = (): JSX.Element => {

    return (
        <BrowserRouter>
            <GoogleAuthLogin
                contentBeforeAuthRedirect={<></>}
                hideRenderContent={true}
                onAuthRedirectPath={''}
            />
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="auth" element={<AppPages.LoginPageComponent />} />
                    <Route path="denied" element={<AppPages.DeniedPageComponent />} />
                    <Route path="matching" element={<AppPages.MatchingDetailPageComponent />} />
                    <Route path="admin" element={<AppPages.AdminIndexPageComponent />} />
                    <Route path="admin/accounts" element={<AppPages.AdminAccountManagementPageComponent />} />
                    <Route path="admin/prioritisation" element={<AppPages.AdminManagementPageComponent />} />
                    <Route index element={<AppPages.SplashScreenComponent />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <RootApp />
        </Provider>
    </ApolloProvider>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
