import { gql } from "apollo-boost";

export type getPropritizartionTasksType = {
	__typename: "TaskType",
	id: string,
	createdAt: null,
	updatedAt: null,
	users: {
		id: string,
		email: string,
		name: string
	}[],
	priorityOrder: number,
	goal: number,
	order: string,
	source: {
		__typename: "SourceType",
		id: string,
		name: string,
		description: string,
		sourceTable: string,
		sourceIdColumnName: string,
		entityIdColumnName: string,
		entityTable: {
			id: number,
			name: string,
		},
		modelTableName: string,
		modelEntityIdColumnName: string,
		automatchingThreshold: number
	},
	filter: {
		__typename: "FilterType",
		id: string,
		clause: string,
		columnName: string
	}
}


export type getPropritizartionType = {
	start: number,
	limit: number,
};



export const getPropritizartionTasksQuery = gql`
	query getTasks ( $start: Float!, $limit: Float!) {
		tasksConnection (
			start: $start,
			limit: $limit
		) {
			pageInfo {
			hasNextPage
			endCursor
			}
			edges {
				node {
					id
					createdAt
					updatedAt
					users {
						id
						email
						name
					}
					priorityOrder
					goal
					order
					source {
						id
						name
						description
						sourceTable
						sourceIdColumnName
						entityTable {
							id
							name
						}
						entityIdColumnName
						modelTableName
						modelEntityIdColumnName
						automatchingThreshold
						__typename
					}
					filter {
						id
						clause
						columnName
						clause
					}
				}
			}
		}
	}
`;



export const getPropritizartionTasksByUserIdQuery = gql`
	query getTasksByUserId ( $userId: Int! ) {
		tasksByUserId(
			userId: $userId
		) {
			id
			createdAt
			updatedAt
			users {
				id
				email
				name
			}
			source {
				id
				name
				description
				sourceTable
				sourceIdColumnName
				entityTable {
					id
					name
				}
				entityIdColumnName
				modelTableName
				modelEntityIdColumnName
				automatchingThreshold
				__typename
			}
			filter {
				id
				clause
				columnName
				clause
			}
			priorityOrder
			goal
			order
		}
	}
`;

export type getProgressOfPropritizartionTaskQueryType = {
	sourceTable: string,
	filterName: string,
	mainTable: string,
	sourceId: string,
	order: string,
	limit: number,
	mainTableColumn: string | null
}

export const getProgressOfPropritizartionTaskQuery = gql`
	query getProgressOfPropritizartionTaskQuery (
		$sourceTable: String!
		$filterName: String!
		$order: String!
		$limit: Float!
	) {
		progress(
			sourceTable: $sourceTable
			filterName: $filterName
			order: $order
			limit: $limit
		) {
			progress
		}
	}
`;



export type deleteTaskQueryModel = {
	taskId: number;
}

export const deleteTaskQuery = gql`
mutation deleteTask ( $taskId: Int! ) {
	deleteTask(
		taskId: $taskId
	)
}`;

export type sourcesQueryDataEntityType = {
	__typename: "SourceType",
	automatchingThreshold: number,
	description: string,
	id: string,
	modelEntityIdColumnName: string,
	name: string,
	sourceTable: string
}

export const sourcesQuery = gql`
query sources {
	sources {
		id,
		name,
		sourceTable,
		modelEntityIdColumnName,
		automatchingThreshold,
		description,
	}
}`;


export type createTaskQueryModel = {
	id?: number;
	sourceId: number;
	filterId: number;
	users: {
		id: number
	}[]
	priorityOrder: number // send as currentTimeStamp as number
	goal: number // 100 ; add on UI
	order: string;
	sorting_column: string; // from source's filter ( columnName ; show clause on UI )
	startDate: Date | string; // add on UI
	endDate: Date | string; // add on UI
}

export const createTaskQuery = gql`
mutation createTask ( $task: InputTaskType! ) {
	createTask(
		task: $task
	) {
		id
	}
}`;


export type updateTaskQueryModel = {
	taskId: number;
	task: createTaskQueryModel
}

export const updateTaskQuery = gql`
mutation updateTask ( $taskId: Int!, $task: InputTaskType! ) {
	updateTask(
		taskId: $taskId
		task: $task
	) {
		id
	}
}`;

export type getFiltersForSourceIdDataEntityType = {
	id: string;
	clause: string;
	columnName: string;
	__typename: "FilterType";
}

export const getFiltersForSourceIdQuery = gql`
query getFiltersSourceById ($sourceId: Int!) {
  getFiltersSourceById(sourceId: $sourceId) {
    __typename
    id
    clause
    columnName
  }
}`

export type updatePriorityTasksQueryModel = {
	id: number;
	priorityOrder: number;
}

export const updatePriorityTasksQuery = gql`
mutation updatePriorityTasks ( $tasks: [InputPriorityTaskType!]! ) {
	updatePriorityTasks(
		tasks: $tasks
	)
}`;
