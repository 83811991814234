import { createSlice } from '@reduxjs/toolkit';

import { GoogleLoginResponse } from 'react-google-login';

import {
	GoogleAuthResponse
} from '../../components/organisms/GoogleAuth/index';

export enum RoleType {
	ADMIN = "admin",
	MATCHER = "matcher",
	GUEST = "guest",
	INACTIVE = "inactive",
	UNKNOWN = "unknown"
}

export type ReducerAuthStateCurrentUser = {
	__typename: "UserType",
	id: string;
	email: string;
	name: string;
	createdAt: string | null;
	updatedAt: string | null;
	photoUrl: string | null;
	userRoles: {
		__typename: "UserRoleType";
		id: string;
		updatedAt: string | null,
		role: {
			__typename: "RoleType",
			id: string,
			name: RoleType
		}
	}[]
};

export type ReducerAuthStateSessionData = {
	token: string;
	tokenId: string;
	expire: number;
	email: string;
	image: string;
	name: string;
};

export type ReducerAuthStateData = {
	profileObj: Pick<GoogleAuthResponse, 'profileObj'> | Pick<GoogleLoginResponse, 'profileObj'>,
	tokenObj: Pick<GoogleAuthResponse, 'tokenObj'> | Pick<GoogleLoginResponse, 'tokenObj'>,
} | null

export type ReducerAuthState = {
	sessionData: ReducerAuthStateSessionData,
	currentUser: ReducerAuthStateCurrentUser | null,
	data: ReducerAuthStateData
};

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		data: null,
		currentUser: {
			id: "",
			name: "",
			email: "",
		},
		sessionData: {
			token: '',
			tokenId: '',
			expire: 0,
			email: '',
			image: '',
			name : ''
		}
	} as ReducerAuthState,
	reducers: {
		setCurrentUserData(state, { payload }: { payload: ReducerAuthStateCurrentUser | null }) {
			state.currentUser = payload;
		},
		AuthUpdateTokenAction(state, { payload }: { payload: ReducerAuthStateSessionData }) {
			state.sessionData = {
				...state.sessionData,
				...payload
			}
		},
		AuthUserAuthenticatedAction(state, { payload }: { payload: GoogleAuthResponse | GoogleLoginResponse }) {
			state.data = {
				profileObj: payload.profileObj as unknown,
				tokenObj: payload.tokenObj as unknown
			} as ReducerAuthStateData;
			state.sessionData = {
				email: payload.profileObj?.email || '',
				image: payload.profileObj?.imageUrl || '',
				name : payload.profileObj?.name || '',
				token: payload.tokenObj?.access_token || '',
				expire: payload.tokenObj?.expires_in || 0,
				tokenId: payload.tokenObj?.id_token || ''
			};
		},
		AuthUserLogoutAction(state) {
			state.data = null;
			state.currentUser = null;
			state.sessionData = {
				token: '',
				tokenId: '',
				expire: 0,
				email: '',
				image: '',
				name : ''
			}
		}
	}
});

export const {
	setCurrentUserData,
	AuthUpdateTokenAction,
	AuthUserAuthenticatedAction,
	AuthUserLogoutAction
} = authSlice.actions;
export default authSlice.reducer;
