import { Icon, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

function DeniedPageComponent(): JSX.Element {

  return (
    <Container className="DeniedPage" maxWidth={'xs'}>
		<Box style={{
			marginTop: '30vh',
		}}>
			<Typography variant="h5">Access Denied <Icon color='error'>remove_circle</Icon></Typography>
			<Typography variant="body2">
				You do not have permission to be where you were trying to go.
				Check your permission and try again.
				<br />
				<br />
				<Link to={'/'}>Return home</Link>
			</Typography>
		</Box>
    </Container>
  );
}

export default DeniedPageComponent;
