import { Button, Icon } from '@mui/material';
import './index.css';
import React from 'react';
import { GoogleLogin, GoogleLogout, GoogleLogoutProps } from 'react-google-login';
/* istanbul ignore next */
import { useNavigate } from 'react-router-dom';
import { GOOGLE_AUTH_CLIENT_ID } from '../../../constants';
import { AuthServiceInstance } from '../../../store/api';

export type GoogleAuthResponse = {
	Ca: string;
	accessToken: string;
	googleId: string;
	profileObj: {
		email: string;
		familyName: string;
		givenName: string;
		googleId: string;
		imageUrl: string;
		name: string;
	},
	tokenId: string;
	tokenObj: {
		access_token: string;
		expires_at: number;
		expires_in: number;
		first_issued_at: number;
		id_token: string;
		idpId: "google";
		login_hint: string;
		session_state: {
			extraQueryParams: {
				authuser: string
			},
			token_type: "Bearer"
		}
	}
};

type GoogleAuthLoginProps = {
	children?: JSX.Element[];
	onAuthRedirectPath?: string;
	delayBeforeAuthRedirect?: number;
	contentBeforeAuthRedirect?: JSX.Element;
	hideRenderContent?: boolean;
	supressRedirectOnFirstRender?: boolean;
}

type GoogleAuthLoginState = {
	authenticated: boolean;
};

export const GoogleAuthLogout = (props: Partial<GoogleLogoutProps>): JSX.Element => {
	return <GoogleLogout
		clientId={GOOGLE_AUTH_CLIENT_ID}
		buttonText="Logout"
		onLogoutSuccess={AuthServiceInstance.onGoogleLogoutSuccessHandler}
		{...props}
	></GoogleLogout>
}
let authRequired = false;

const GoogleAuthLogin = (props: GoogleAuthLoginProps): JSX.Element => {

	const [state, setValue] = React.useState({
		authenticated: false,
	} as GoogleAuthLoginState);

	const setState = (update: Partial<GoogleAuthLoginState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const navigate = useNavigate();

	const { children, onAuthRedirectPath, delayBeforeAuthRedirect, contentBeforeAuthRedirect, hideRenderContent, supressRedirectOnFirstRender } = props;
	const { authenticated } = state;

	return <div className={'GoogleAuth-Container'}>
		<GoogleLogin
			clientId={GOOGLE_AUTH_CLIENT_ID}
			buttonText={'Google Auth'}
			onSuccess={(res) => {
				return AuthServiceInstance.onGoogleAuthSuccessHandler(res, () => {
					const authRequiredRef = authRequired;
					setState({
						authenticated: true
					}, () => {
						authRequired = false;
						if (onAuthRedirectPath) {
							setTimeout(() => {
								if (!state.authenticated) {
									if (!supressRedirectOnFirstRender || authRequiredRef) {
										navigate(onAuthRedirectPath);
									}
								}
							}, delayBeforeAuthRedirect || 0);
						}
					});
				})
			}}
			onFailure={(res) => {
				setState({
					authenticated: false
				}, () => {
					AuthServiceInstance.onGoogleAuthFailureHandler(res);
				});
			}}
			cookiePolicy={'single_host_origin'}
			isSignedIn={true}
			render={
				renderProps => (
					authenticated
					? (
						contentBeforeAuthRedirect
						||
						<>
							Authenticated
						</>
					) : (
						hideRenderContent ? <></> : <>
						{ children || []}
						<Button
							className={'GoogleAuth-Button'}
							onClick={() => {
								authRequired = true;
								renderProps.onClick();
							}}
							disabled={renderProps.disabled}
							variant="contained"
						>
							<Icon>account_box</Icon>

							Google Authentication
						</Button>
					</>)
				)
			}
		/>
	</div>
};

export default GoogleAuthLogin;
