import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function ProgressCircleComponent(
  props: CircularProgressProps & { value: number, showProcent?: boolean },
): JSX.Element {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant={props.variant || "determinate"} {...{ value: props.value * 100 }} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
			props.showProcent && <Typography
				variant="caption"
				component="div"
				color="text.secondary"
			>{`${(props.value * 100).toFixed(2).replace(/(\.|)[0]+$/, '')}%`}</Typography>
		}
      </Box>
    </Box>
  );
}

export default ProgressCircleComponent;
