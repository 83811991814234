import Notification from 'rc-notification';
import { NotificationInstance } from 'rc-notification/es/Notification';

import 'rc-notification/assets/index.css';

let requestedNotification: Promise<NotificationInstance> | null = null;

const notification = (): Promise<NotificationInstance> => {
	if (requestedNotification) return requestedNotification;
	requestedNotification = new Promise((resolve, _reject) => {
		Notification.newInstance({
		}, notification => {
			resolve(notification);
		});
	});
	return requestedNotification;
};

export const ConsoleUI = {
	log: async (...messages: (Error | string | object | number)[]) => {
		(await notification()).notice({
			content: `${messages.map(message => message.toString()).join('\n')}`,
			closable: true,
			duration: 1
		})
	},
	error: async (...messages: (Error | string | object | number)[]) => {
		(await notification()).notice({
			content: `${messages.map(message => message.toString()).join('\n')}`,
			closable: true,
			duration: 20,
			style: {
				backgroundColor: "crimson",
				color: "white"
			}
		})
	},
	warn: async (...messages: (Error | string | object | number)[]) => {
		(await notification()).notice({
			content: `${messages.map(message => message.toString()).join('\n')}`,
			closable: true,
			style: {
				backgroundColor: "golderrod"
			},
			duration: 10
		})
	},
	info: async (...messages: (Error | string | object | number)[]) => {
		(await notification()).notice({
			content: `${messages.map(message => message.toString()).join('\n')}`,
			closable: true,
			duration: 3,
			style: {
				backgroundColor: "royalblue",
				color: "white"
			}
		})
	},
	monitor: <D>(request: Promise<D>, successMessage?: string | null): Promise<D> => {
		return new Promise((resolve, reject) => {
			request.then((data) => {

				const dataCheck = data as unknown as (D & { errors?: {message: string}[]})
				if (dataCheck && typeof(dataCheck?.errors) === "object" && Array.isArray(dataCheck?.errors)) {
					const errorMessage = dataCheck.errors.map(err => err.message || '').filter(item => !!item).join("\n");

					if (errorMessage) {
						ConsoleUI.warn(errorMessage);
					} else {
						if (successMessage) {
							ConsoleUI.info(successMessage);
						}
					}
				} else {
					if (successMessage) {
						ConsoleUI.info(successMessage);
					}
				}
				resolve(data);
			}, (err: Error) => {
				ConsoleUI.error(err);
				reject(err);
			});
		});
	}
}

Object.assign(
	window,
	{
		console_original: window.console
	}
);

Object.assign(
	window,
	{
		console: {
			...(((window as unknown) as { console_original: { [key: string]: unknown }})?.console_original || {}),
			ui: ConsoleUI
		}
	}
);
