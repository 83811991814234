import { gql } from "apollo-boost";
import { RoleType } from "../../store/features/auth";

export type getUsersQueryOptionsType = {
	start: number,
	limit: number,
	orderBy: 'ID',
	orderDirection: 'DESC' | 'ASC'
};



export type usersConnectionResultType = {
	usersConnection: {
		pageInfo: {
			hasNextPage: boolean,
			endCursor: ""
		},
		totalCount: number,
		edges: {
			node: usersConnectionType
		}[]
	}
}

export type usersConnectionType = {
	name: string;
	email: string;
	photoUrl: string;
}

export const getUsersConnectionQuery = gql`
query GetUsersConnection ( $start: Float!, $orderBy: UsersOrderBy, $orderDirection: OrderDirection, $limit: Float!) {
	usersConnection(
		start: $start,
		limit: $limit,
		orderBy: $orderBy,
		orderDirection: $orderDirection
	) {
		pageInfo {
			hasNextPage
			endCursor
		}
		edges {
			node {
				id
				name
				email
				userRoles {
				id
				updatedAt
					role {
						id
						name
					}
				}
				createdAt
				updatedAt
				photoUrl
			}
		}
	}
}
`;

export const getUsersByEmailQuery = gql`
query GetUsersConnection ( $email: String ) {
	usersConnection(
		start: 0,
		limit: 1,
		filters: {
			email: $email
		}
	) {
		edges {
			node {
				id
				name
				email
				userRoles {
				id
				updatedAt
					role {
						id
						name
					}
				}
				createdAt
				updatedAt
				photoUrl
			}
		}
	}
}
`;


export const setUserRoleMatcher = gql`
mutation updateUser ( $id: ID! ) {
	updateUser(
		user: {
			id: $id,
			userRoles: [
				{
					name: MATCHER
				}
			]
		}
	) {
		id
	}
}
`;

export const setUserRoleGuest = gql`
mutation updateUser ( $id: ID! ) {
	updateUser(
		user: {
			id: $id,
			userRoles: [
				{
					name: GUEST
				}
			]
		}
	) {
		id
	}
}
`;

export const setUserRoleInactive = gql`
mutation updateUser ( $id: ID! ) {
	updateUser(
		user: {
			id: $id,
			userRoles: [
				{
					name: INACTIVE
				}
			]
		}
	) {
		id
	}
}
`;


export const setUserRoleAdmin = gql`
mutation updateUser ( $id: ID! ) {
	updateUser(
		user: {
			id: $id,
			userRoles: [
				{
					name: ADMIN
				}
			]
		}
	) {
		id
	}
}`;


export type usersListQueryDataEntityType = {
	__typename: "UserType",
	name: string,
	id: string,
	email: string,
	userRoles: {
		__typename: "UserRoleType",
		id: string,
		role: {
			__typename: "RoleType",
			id: string,
			name: RoleType
		}
	}[],
	photoUrl: string
}

export const usersListQuery = gql`
query {
  users {
    __typename,
    name,
    id,
    email,
    userRoles {
      id,
      role {
        id,
        __typename,
        name
      }
      __typename
    },
    photoUrl
  }
}`;
