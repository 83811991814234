import { ReducerAuthState, ReducerAuthStateCurrentUser, RoleType } from "../features/auth";
import { ReducerGlobalState } from "../store";


export const authStateSelector = ({auth}: {auth: ReducerAuthState}): ReducerAuthState => (auth);

export const authStateAccessTokenSelector = (state: ReducerGlobalState): string => (authStateSelector(state).sessionData?.token);

export const authStateIsAuthenticated = (state: ReducerGlobalState): boolean => (!!authStateSelector(state).sessionData?.token);

export const authStateUserEmailSelector = (state: ReducerGlobalState): string => (authStateSelector(state).sessionData?.email);

export const authStateUserAvatarSelector = (state: ReducerGlobalState): string => (authStateSelector(state).sessionData?.image);

export const authStateUserNameSelector = (state: ReducerGlobalState): string => (authStateSelector(state).sessionData?.name);

export const authStateCurrentUser = (state: ReducerGlobalState): ReducerAuthStateCurrentUser | null => {
	return authStateIsAuthenticated(state) ? authStateSelector(state).currentUser : null;
};

export const authStateCurrentUserRoleTypes = (state: ReducerGlobalState): RoleType[] => {
	return authStateCurrentUser(state)?.userRoles?.map(role => role.role.name) || [];
};

export const authStateIsCurrentUserAdmin = (state: ReducerGlobalState): boolean => authStateCurrentUserRoleTypes(state).includes(RoleType.ADMIN);
export const authStateIsCurrentUserMatcher = (state: ReducerGlobalState): boolean => authStateCurrentUserRoleTypes(state).includes(RoleType.MATCHER);
export const authStateIsCurrentUserGuest = (state: ReducerGlobalState): boolean => authStateCurrentUserRoleTypes(state).includes(RoleType.GUEST);
