import { configureStore } from '@reduxjs/toolkit'
import authReducer, { ReducerAuthState } from './features/auth'

export type ReducerGlobalState = {
  auth: ReducerAuthState
};

export const store = configureStore({
  reducer: {
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['auth', 'payload'],
    },
  }),
});
