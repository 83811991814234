import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStateIsAuthenticated } from '../../store/selectors/auth';

function SplashScreenComponent(): JSX.Element {
	const navigate = useNavigate();

	const  isAuthenticated = useSelector(authStateIsAuthenticated);

	const redirectPath = isAuthenticated
		? 'matching'
		: 'auth';

	React.useEffect(() => {
		navigate(redirectPath);
	});

  return <></>;
}

export default SplashScreenComponent;
