import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStateIsAuthenticated, authStateIsCurrentUserAdmin, authStateIsCurrentUserMatcher, authStateUserAvatarSelector, authStateUserEmailSelector, authStateUserNameSelector } from '../../store/selectors/auth';
import GoogleAuthLogin, { GoogleAuthLogout } from '../organisms/GoogleAuth';
import { AuthServiceInstance } from '../../store/api';

type LoginPageComponentProps = {
	success?: string,
}

type LoginPageComponentState = {
	authenticated: boolean,
}

const LoginPageComponent = (props: LoginPageComponentProps): JSX.Element => {

	const authenticated   = useSelector(authStateIsAuthenticated);
	const userAvatarImage = useSelector(authStateUserAvatarSelector);
	const userEmail       = useSelector(authStateUserEmailSelector);
	const userName        = useSelector(authStateUserNameSelector);


	const isAdmin = useSelector(authStateIsCurrentUserAdmin);
	const isMatcher = useSelector(authStateIsCurrentUserMatcher);

	const [state, setValue] = React.useState({
		authenticated
	} as LoginPageComponentState);

	const setState = (update: Partial<LoginPageComponentState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const navigate = useNavigate();

	const onAuthenticatedContent = (): JSX.Element => {
		return <>
			<img src={userAvatarImage} />
			<Typography variant="h5" component="h3">{userName}</Typography>
			<Typography variant="subtitle2" component="p">{userEmail}</Typography>

			<br />
			<br />
			<GoogleAuthLogout
				render={renderProps => {
					return <Button variant="contained" color="error" onClick={renderProps.onClick}>Logout</Button>
				}}
				onLogoutSuccess={() => {
					setState({ authenticated: false }, () => {
						AuthServiceInstance.onGoogleLogoutSuccessHandler();

						navigate('/');
					});
				}}
			></GoogleAuthLogout>
			<br />

			<hr />

			<br />
			{ (isAdmin || isMatcher) ? <Button variant="contained" onClick={() => {
				navigate(props.success || '/admin');
			}}>Dashboard</Button> : ''}
		</>
	}

	return (
		<div className="LoginPage">
			<GoogleAuthLogin
				contentBeforeAuthRedirect={onAuthenticatedContent()}
				onAuthRedirectPath='/admin'
				supressRedirectOnFirstRender={true}
			>
				<h2>Welcome to CRED Data Matching</h2>
				<p>
					Only authorised CRED accounts are able to use the app.
					<br />
					<br />
					Please contact <a href="mailto:cherry@credinvestments.com">cherry@credinvestments.com</a> if you have any questions.
				</p>
				<br />
			</GoogleAuthLogin>
		</div>
	)
}

export default LoginPageComponent;
