import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client';

export const localGraphQLURL = "https://cred-matching-api-staging.herokuapp.com/graphql";
// export const localGraphQLURL = "http://localhost:9000/graphql";
export const GraphQLCache = new InMemoryCache();

const httpLink = new HttpLink({ uri: localGraphQLURL });


const authMiddleware = new ApolloLink((operation, forward) => {

  // add the authorization to the headers

  operation.setContext(({ headers = {} }) => ({

    headers: {

      ...headers,

      authorization: (sessionStorage.getItem('accessTokenId') || null),

    }

  }));


  return forward(operation);

});


export const client = new ApolloClient({
  uri: localGraphQLURL,
  cache: GraphQLCache,
  link: concat(authMiddleware, httpLink),
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
