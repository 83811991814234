import React from 'react';
import { Container } from '@mui/system';

import Box from '@mui/material/Box';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';

import { Autocomplete, Breadcrumbs, Checkbox, Chip, LinearProgress, Link, TextField, Typography } from '@mui/material';

import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { authStateIsCurrentUserAdmin } from '../../store/selectors/auth';
import DeniedPageComponent from './Denied';

import { useSelector } from 'react-redux';
import { getUsersConnectionQuery, getUsersQueryOptionsType, setUserRoleAdmin, setUserRoleMatcher, setUserRoleGuest, setUserRoleInactive } from '../../graphql/queries/queryUsersConnections';
import { client } from '../../utils/graphQLClient';
import { ConsoleUI } from '../../utils/notification.service';
import { RoleType } from '../../store/features/auth';
import { useNavigate } from 'react-router-dom';


type AdminAccountManagementDataRow = {
	id: number;
	name: string;
	email: string;
	isNew: boolean;
	roles: { id: number, name: string }[];
}

export type AdminAccountManagementPageComponentProps = {
	rows?: AdminAccountManagementDataRow[]
};

export type AdminAccountManagementPageComponentState = {
	rows: AdminAccountManagementDataRow[],
	loading: boolean,
	showInactive: boolean,
};


const roles = [
	{
		label: "Admin",
		id: RoleType.ADMIN
	},
	{
		label: "Matcher",
		id: RoleType.MATCHER
	},
	{
		label: "Guest",
		id: RoleType.GUEST
	},
	{
		label: "Inactive",
		id: RoleType.INACTIVE
	}
];

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'Id',
		width: 120,
		type: 'number',
		headerAlign: 'left'
	},
	{
		field: 'name',
		headerName: 'Name',
		minWidth: 180,
		editable: false,
		renderCell: (params: GridRenderEditCellParams) => {
			return params.row.isNew ? <i title="New automatically added user">{params.row[params.field]}&nbsp;<sup>new user</sup></i> : <>{params.row[params.field]}</>
		}
	},
	{
		field: 'email',
		headerName: 'Email',
		minWidth: 240,
		editable: false
	},
	{
		field: 'roles',
		headerName: 'Roles',
		minWidth: 270,
		renderEditCell: (params: GridRenderEditCellParams): JSX.Element => {
			return <Autocomplete
				disablePortal
				options={roles}
				sx={{ width: 300 }}
				onChange={async (_event, role) => {
					if (role?.id === RoleType.ADMIN) {
						params.row.roles[0].name = RoleType.ADMIN;
						ConsoleUI.monitor(
							client.mutate({
								mutation: setUserRoleAdmin,
								fetchPolicy: "network-only",
								variables: { id: params.row.id }
							}),
							"Role updated to [Admin]"
						);
					} else if (role?.id === RoleType.INACTIVE) {
						params.row.roles[0].name = RoleType.INACTIVE;
						ConsoleUI.monitor(
							client.mutate({
								fetchPolicy: 'network-only',
								mutation: setUserRoleInactive,
								variables: { id: params.row.id
							}}),
							"Role updated to [Inactive]"
						);
					} else if (role?.id === RoleType.MATCHER) {
						params.row.roles[0].name = RoleType.MATCHER;
						ConsoleUI.monitor(
							client.mutate({
								fetchPolicy: 'network-only',
								mutation: setUserRoleMatcher,
								variables: { id: params.row.id
							}}),
							"Role updated to [Matcher]"
						);
					} else if (role?.id === RoleType.GUEST) {
						params.row.roles[0].name = RoleType.GUEST;
						ConsoleUI.monitor(
							client.mutate({
								fetchPolicy: 'network-only',
								mutation: setUserRoleGuest,
								variables: { id: params.row.id
							}}),
							"Role updated to [Guest]"
						);
					}

					params.api.stopCellEditMode({
						id: params.id,
						field: params.field
					});
					params.api.forceUpdate();
				}}
				renderInput={(params) => <TextField {...params} label="Roles" />}
			/>;
		},
		renderCell : (params: GridRenderCellParams) => {
			return <>
				{
					params.row.roles.map((role: { id: number, name: string }, _index: number) => <Chip key={role.id} label={role.name} />)
				}
			</>;
		},
		editable: true
	},
];

const demo_rows: AdminAccountManagementDataRow[] = [];



const AdminAccountManagementPageComponent = (props: AdminAccountManagementPageComponentProps):JSX.Element => {

	const isAdmin = useSelector(authStateIsCurrentUserAdmin);

	const [state, setValue] = React.useState({
		rows: [
			...(props.rows || []),
			...demo_rows
		],
		loading: false,
		showInactive: false
	} as AdminAccountManagementPageComponentState);


	const setState = (update: Partial<AdminAccountManagementPageComponentState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}
	interface UserRole {
		id: number;
		role: { name: string };
	}
	interface UserNode {
		id: number;
		name: string;
		email: string;
		userRoles: UserRole[];
	}
	const loadServerRows = async (newRowLength: number) => {
		if (state.loading) return;

		setState({ ...state, loading: true });

		try {
			const data = await ConsoleUI.monitor(client.query({
				fetchPolicy: 'network-only',
				query: getUsersConnectionQuery,
				variables: {
					start: state.rows.length,
					limit: newRowLength,
					orderBy: 'ID',
					orderDirection: 'ASC'
				} as getUsersQueryOptionsType
			}));

			const fetchedRows: AdminAccountManagementDataRow[] = data.data.usersConnection.edges.map((_edge: { node: UserNode }) => ({
				id: _edge.node.id,
				name: _edge.node.name,
				email: _edge.node.email,
				isNew: _edge.node.userRoles.length === 0,
				roles: (_edge.node.userRoles.map((_role: UserRole) => ({
					id: _role.id,
					name: _role.role.name
				}))) || [{ id: 1, name: RoleType.GUEST }]
			}));

			const newRows = fetchedRows.filter(fetchedRow => !state.rows.some(existingRow => existingRow.id === fetchedRow.id));


			// Directly setting the new state
			setState({
				...state,
				loading: false,
				rows: [...state.rows, ...newRows]
			  });

		} catch (error) {
			ConsoleUI.error('Error fetching data:', error as Error);
			// Directly setting the new state
			setState({
				...state,
				loading: false
			  });
		}
	};




	const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = (params) => {
		if (state.rows.length <= 200) {
			loadServerRows(Math.max(params.viewportPageSize * 2, 200));
		}
	};

	const { rows, loading } = state;

	const navigate = useNavigate();

	if (!isAdmin) {
		return <DeniedPageComponent />
	}
	return (
		<Container className="AdminManagment" maxWidth={'xl'}>

			<br />

			<Typography variant="h5">Admin: Account Management</Typography>

			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/" onClick={(ev) => {
					ev.preventDefault();
					navigate('/');
				}}>
					Home
				</Link>
				<Link
					underline="hover"
					color="inherit"
					href="admin" onClick={(ev) => {
						ev.preventDefault();
						navigate('/admin');
					}}
				>
					Admin
				</Link>
				<Typography color="text.primary">Account Management</Typography>
			</Breadcrumbs>

			<br />

			<Checkbox checked={state.showInactive} onClick={() => {
				setState({
					showInactive: !state.showInactive,
				})
			}} /> Show INACTIVE

			<br />

			<Box sx={{ height: 800, width: '100%' }}>
				<DataGridPro
						rows={rows.filter(item => {
							if (state.showInactive) return true;

							return item.roles[0]?.name !== "inactive";
						})}
						columns={columns}
						loading={loading}
						disableSelectionOnClick
						editMode='cell'
						hideFooterPagination
						onRowsScrollEnd={handleOnRowsScrollEnd}
						components={{
							LoadingOverlay: LinearProgress,
						}}
						experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
		</Container>
	);
}

export default AdminAccountManagementPageComponent;
