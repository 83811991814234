import React, { useEffect } from 'react';
import { createTaskQueryModel, getFiltersForSourceIdDataEntityType, getFiltersForSourceIdQuery, sourcesQuery, sourcesQueryDataEntityType } from "../../../graphql/queries/queryPrioritizationTasks";
import { Autocomplete, Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ConsoleUI } from '../../../utils/notification.service';
import { client } from '../../../utils/graphQLClient';
import DeleteIcon from '@mui/icons-material/Delete';
import { usersListQuery, usersListQueryDataEntityType } from '../../../graphql/queries/queryUsersConnections';

export type renderCreateTaskDialogState = {
	sources: sourcesQueryDataEntityType[] | null,
	users: usersListQueryDataEntityType[] | null,
	task: createTaskQueryModel,
	filters: getFiltersForSourceIdDataEntityType[] | null
}

export type renderCreateTaskDialogProps = {
	open: boolean,
	handleClose: () => void,
	handleOk: (task: createTaskQueryModel) => void,
	handleCancel: () => void,
	editFields: string[] | null,
	skipSourceIds: string[],
	task?: createTaskQueryModel
}
export const RenderCreateTaskDialog = (props: renderCreateTaskDialogProps):JSX.Element => {

	const [state, setValue] = React.useState({
		sources: null,
		users: null,
		filters: null,
		task: Object.assign({
			id: 0,
			endDate: new Date(),
			startDate: new Date(),
			priorityOrder: 0,
			filterId: 0,
			goal: 0,
			order: "",
			sorting_column: "",
			sourceId: 0,
			users: []
		}, props.task || {})
	} as renderCreateTaskDialogState);

	useEffect(() => {
		if (!state.sources) {
			ConsoleUI.monitor(
				Promise.all(
					[
						client.query({ fetchPolicy: 'network-only', query: sourcesQuery, variables: {} }),
						client.query({ fetchPolicy: 'network-only', query: usersListQuery, variables: {} })
					]
				)
			).then(([
				sourcesData, usersData
			]: [
				{ data: { sources: sourcesQueryDataEntityType[] } | null, errors?: unknown},
				{ data: { users: usersListQueryDataEntityType[] } | null, errors?: unknown}
			]) => {
				const sources = (sourcesData.data?.sources || []).map(v => v);
				sources.sort((a, b) => {
					if (a.name === b.name) return 0;
					return a.name < b.name ? -1 : 1;
				})
				if (props.task && (!state.task || props.task.id !== state.task.id)) {
					updateTask(props.task, () => {
						loadFilters({
							sources,
							users: usersData.data?.users || []
						});
					});
				} else {
					setState({
						task: undefined,
						sources,
						users: usersData.data?.users || []
					});
				}
			});
		} else {
			// debugger; // eslint-disable-line
			if (props.task && (!state.task || props.task.id !== state.task.id)) {
				loadFilters(
					{
						task: Object.assign(state.task || {}, props.task || {})
					}
				);
			} else {
				setState({ task: undefined })
			}
		}
	}, [props.task]);

	const setState = (update: Partial<renderCreateTaskDialogState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const updateTask = (taskData: Partial<createTaskQueryModel>, cb?: (task: createTaskQueryModel) => void): void => {
		const task = Object.assign(state.task || {}, taskData || {});
		setState({
			task
		}, () => {
			if (cb) cb(task);
		});
	}

	const loadFilters = (stateUpdate: Partial<renderCreateTaskDialogState>) => {
		const task = Object.assign(state.task || {}, stateUpdate.task || {});
		if (!task?.sourceId) return;

		ConsoleUI.monitor(
			client.query({ fetchPolicy: 'network-only', query: getFiltersForSourceIdQuery, variables: {
				sourceId: task.sourceId
			} })
		).then((data : { data: { getFiltersSourceById: getFiltersForSourceIdDataEntityType[] } }) => {
			if (task) {
				if (task.filterId) {
					task.sorting_column = (data.data.getFiltersSourceById.find(
						(filter: getFiltersForSourceIdDataEntityType) => parseInt(filter.id, 10) === task.filterId
					) || { columnName: "" }).columnName
				}
			}

			setState({
				...stateUpdate,
				task,
				filters: data.data.getFiltersSourceById
			})
		});
	}

	const isLoading = () => !!(
		state.sources === null || ( props.editFields && `${props?.task?.id}` !== `${state?.task?.id}` )
	)


	return (
		<div>
		  <Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		  >
			<DialogTitle id="alert-dialog-title">
				{
					props.editFields === null ? 'Create a new task' : 'Update the task'
				}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" style={{ width: '90vw', maxWidth: '450px' 	}}>
					{
						props.editFields === null || props.editFields.includes('source') ?
						<>
							<br />
							<br />
							<FormControl fullWidth>
								{
									state.sources === null ? 'Loading sources...' : (
										<>
											<Autocomplete
												disablePortal
												id="create-task--source-id"
												value={state.sources.filter(source => `${state.task?.sourceId}` === `${source.id}`).map(source => ({ label: source.name, id: source.id }))[0]}
												disabled={isLoading()}
												onChange={(_event, item, actionType) => {
													if (actionType === "selectOption") {
														if (item) {
															updateTask({
																sourceId: parseInt(`${item.id}`, 10) || 0,
															}, (task) => {
																loadFilters({ task });
															});
														}
													}
												}}
												options={state.sources.map(source => ({ label: source.name, id: source.id }))}
												renderInput={(params) => <TextField {...params} label="Source" />}
											/>
										</>
									)
								}
							</FormControl>
							<br />
						</> : ''
					}
					{
						props.editFields === null || props.editFields.includes('sorting_column') ?
						<>
							<br />
							<FormControl fullWidth>
							<InputLabel id="create-task--sorting-column--label">Sorting column name</InputLabel>
								<Select
									labelId="create-task--sorting-column--label"
									id="create-task--sorting-column"
									value={state.task?.sorting_column || ''}
									disabled={isLoading()}
									label="Sorting column name"
									onChange={(event) => {
										if (event.target.value) {
											const filterId = parseInt(
												state.filters?.find(filter => filter.columnName === event.target.value)?.id || "0",
												10
											) || 0;
											updateTask({
												sorting_column: event.target.value,
												filterId
											});
										}
									}}
								>
									{
										Array.isArray(state.filters) ? (
											state.filters?.map(filter => <MenuItem key={filter?.id || ''} value={filter?.columnName || ''}>{filter?.clause}</MenuItem>)
										) : null
									}
								</Select>
							</FormControl>
							<br />
						</> : ''
					}
					{
						props.editFields === null || props.editFields.includes('sorting_order') ?
						<>
							<br />
							<FormControl fullWidth>
								<InputLabel id="create-task--sorting-order--label">Sorting order direction</InputLabel>
								<Select
									labelId="create-task--sorting-order--label"
									id="create-task--sorting-order"
									value={state.task?.order}
									disabled={isLoading()}
									label="Sorting order direction"
									onChange={(event) => {
										if (event.target.value) {
											updateTask({
												order: event.target.value || 'ASC',
											});
										}
									}}
								>
									{/* <MenuItem value={''}><i>Choose sorting</i></MenuItem> */}
									<MenuItem value={'ASC'}>ASC</MenuItem>
									<MenuItem value={'DESC'}>DESC</MenuItem>
								</Select>
							</FormControl>
							<br />
						</> : ''
					}
					{
						props.editFields === null || props.editFields.includes('goal') ?
						<>
							<br />
							<FormControl fullWidth>
								<TextField
									label="Goal"
									id="create-task--goal"
									disabled={isLoading()}
									InputProps={{
										type: "number"
									}}
									value={state.task?.goal || ''}
									onChange={(event) => {
										if (event.target.value) {
											updateTask({
												goal: Math.max(1, parseInt(event.target.value, 10) || 0),
											});
										}
									}}
								/>
							</FormControl>
							<br />
						</> : ''
					}
					{
						props.editFields === null || props.editFields.includes('assigned_matchers') ?
						<>
							<br />
							<FormControl fullWidth>
								<InputLabel id="create-task--assigned_matchers--label">Assigned Matchers</InputLabel>
								{
									state.users === null ? 'Loading users...' : (
										<Select
											labelId="create-task--assigned_matchers--label"
											id="create-task--assigned_matchers"
											value={state.task?.users?.map(item => item.id) || []}
											label="Assigned Matchers"
											multiple={true}
											multiline={true}
											disabled={isLoading()}
											onChange={(event) => {
												if (event.target.value) {
													updateTask({
														users: ((event.target.value as unknown as string[]) || []).map(id => ({
															id: parseInt(`${id}`, 10) || 0
														})).filter((item, index, arr) => (index === arr.findIndex(ref => ref.id === item.id))),
													});
												}
											}}
											renderValue={(values) => {
												return values.map(id => {
													return state?.users?.find(item => `${item.id}` === `${id}`);
												}).filter(item => !!item).map(user => {
													if (!user) return '';
													return <div style={{display: 'block', position: 'relative' }}>
														<div>{user.name}
															<Avatar style={{
																		marginRight: "15px",
																		float: 'left'
																	}} src={user.photoUrl} unsafe-inline ></Avatar>
															<sup>&nbsp;<i>( id: {user.id} )</i></sup>
															<br />
															<sup><i>{user.email}</i></sup>
														</div>
													</div>
												})
											}}
										>
											{
												Array.isArray(state.users) ? (
													state.users.map((user) => {
														return <MenuItem key={`user-${user.id}`} value={parseInt(user.id, 10) || 0} title={user.email}>
															<Avatar style={{
																marginRight: "15px"
															}} src={user.photoUrl} unsafe-inline ></Avatar>
															<div>{user.name}
																<sup>&nbsp;<i>( id: {user.id} )</i></sup>
																<br />
																<sup><i>{user.email}</i></sup>
															</div>
															{
																state?.task?.users?.find(item => `${item.id}` === `${user.id}`) ? (
																	<IconButton color="error" component="button" style={{ position: 'absolute', right: "10px", top: "4px" }} onClick={(ev) => {
																		ev.preventDefault();
																		ev.stopPropagation();
																		updateTask({
																			users: (
																				state?.task?.users || []
																			).filter(item => `${item.id}` !== `${user.id}`)
																		})
																	}}>
																		<DeleteIcon />
																	</IconButton>
																) : ''
															}
														</MenuItem>
													})
												) : ''
											}
										</Select>
									)
								}
							</FormControl>
						</> : ''
					}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleCancel}>Cancel</Button>
				<Button onClick={
					() => {
						props.handleOk(state.task)
					}
				} disabled={isLoading()} autoFocus>
					{
						(() => {
							const message = (
								props.editFields === null ? 'Create' : 'Update'
							);
							return (
								!isLoading()
							) ? message : 'Preparing...'
						})()
					}
				</Button>
			</DialogActions>
		  </Dialog>
		</div>
	);
}
