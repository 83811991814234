import LoginPageComponent from "./Login";
import DeniedPageComponent from "./Denied";
import AdminIndexPageComponent from './AdminIndex/index';
import AdminManagementPageComponent from './AdminManagement';
import AdminAccountManagementPageComponent from "./AdminAccountManagement";
import MatchingDetailPageComponent from './MatchingDetail';
import SplashScreenComponent from "./SplashScreen";

export const AppPages = {
	LoginPageComponent,
	DeniedPageComponent,
	AdminIndexPageComponent,
	AdminAccountManagementPageComponent,
	AdminManagementPageComponent,
	MatchingDetailPageComponent,
	SplashScreenComponent,
};


