import { gql } from "apollo-boost";

export type insertMatchVariablesSetVariablesType = {
	sourceId: number;
	userId: number;
	taskId: number;
	entityValue: number | null;
	probability: number | null;
	isMatch: "true" | "false" | "unsure" | "manual";
	comment: string | null;
	tableModelName: string;
	data: string | null;
	multiples: number;
	sourceValue: string;
	modelDate: string | null;
	createdByUserId: string | null;
	updatedByUserId: string | null;
}

		// $sourceId: Float! # current_task.source.
		// $userId: Float! # current_user.id
		// $taskId: Float! # current_task.id
		// $entityValue: Float! # same as in matching details call ( from source ) -> columns.find(where name = current_task.source.modelEntityIdColumnName).value
		// 	# if "manual" then the manulal ID entered
		// $probability: Float # from columns
		// 	# if "manual" then null
		// $isMatch: String # "true" | "false" | "unsure" | "manual"
		// $comment: String # inserted by user ; just for "unsure"
		// 	# if "manual" then null
		// $tableModelName: String! # current_task.source.modelTableName
		// $data: String! # JSON.stringify( for current column )
		// 	# if "manual" then null
		// $multiples: Float! # matchingDetails.columns.length
		// 	# if "manual" then matchingDetails.columns.length + 1
		// $sourceValue: String! # "sourceid_a" - always
		// $modelDate: DateTime! # from columns
		// 	# if "manual" then null
		// $createdByUserId: String # current_user.id
		// $updatedByUserId: String # current_user.id
export const insertMatchingActivityQuery = gql`
	mutation insertMatchingActivity(
		$matching: InputInsertMatchingActivity!
	) {
		insertMatchingActivity(
			matching: $matching
		) {
			id
		}
	}
`;

// modelTable: String
// goal: Int
// entityIdColumnName: String
// userId: String
// filterColumn: String
// user: OrderType

export const getMatchingDetailsQuery = gql`
	query getMatchingDetails(
		$matching: OutputMatchingActivityDetails!
	) {
		getMatchingDetails(
			matching: $matching
		) {
			tableName
			tableEntryId
			columns {
				name,
				value
			}
		}
	}
`;


export const undoMatching = gql`
	query undoMatching(
		$userId: String!
	) {
		undoMatching(
			userId: $userId
		) {
			message
		}
	}
`;

