import React from 'react';
import { Container } from '@mui/system';

import Box from '@mui/material/Box';

import { Breadcrumbs, Link, Typography } from '@mui/material';

import { authStateIsCurrentUserAdmin, authStateIsCurrentUserMatcher } from '../../../store/selectors/auth';
import DeniedPageComponent from '../Denied';

import "./styles.css";

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export type AdminIndexPageComponentProps = {
	skipAuthCheck?: boolean
};

export type AdminIndexPageComponentState = {
	loading: boolean
};

const AdminIndexPageComponent = (props: AdminIndexPageComponentProps):JSX.Element => {

	const isAdmin = useSelector(authStateIsCurrentUserAdmin);
	const isMatcher = useSelector(authStateIsCurrentUserMatcher);

	const [state, setValue] = React.useState({
		loading: false
	} as AdminIndexPageComponentState);

	// eslint-disable-next-line
	const setState = (update: Partial<AdminIndexPageComponentState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const navigate = useNavigate();


	if (!isAdmin && !isMatcher && !props.skipAuthCheck) {
		return <DeniedPageComponent />
	}

	return (
		<Container className="AdminIndex" maxWidth={'xl'}>

			<br />

			<Typography variant="h5">Dashboard</Typography>

			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/" onClick={(ev) => {
					ev.preventDefault();
					navigate('/');
				}}>
					Home
				</Link>
			</Breadcrumbs>

			<br />

			<Box sx={{ height: 800, width: '100%' }}>
				{ (isAdmin || isMatcher) ? <Link underline="hover" color="inherit" variant="overline" href="/matching" onClick={(ev) => {
					ev.preventDefault();
					navigate('/matching');
				}}>
					<Typography variant="h6">Matching</Typography>
					<Typography variant="inherit">Match Cred Sources to entities</Typography>
				</Link> : '' }

				{ isAdmin ? <Link underline="hover" color="inherit" variant="overline" href="/admin/prioritisation" onClick={(ev) => {
					ev.preventDefault();
					navigate('/admin/prioritisation');
				}}>
					<Typography variant="h6">Tasks Overview</Typography>
					<Typography variant="inherit">Manage Prioritisation</Typography>
				</Link> : '' }

				{ isAdmin ? <Link underline="hover" color="inherit" variant="overline" href="/admin/accounts" onClick={(ev) => {
					ev.preventDefault();
					navigate('/admin/accounts');
				}}>
					<Typography variant="h6">Account Management</Typography>
					<Typography variant="inherit">Manage Users, update, add or remove</Typography>
				</Link> : '' }
			</Box>
		</Container>
	);
}

export default AdminIndexPageComponent;
