import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import './App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';

import { useSelector } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { grey } from '@mui/material/colors';
import { authStateIsAuthenticated, authStateIsCurrentUserAdmin, authStateIsCurrentUserMatcher, authStateUserAvatarSelector, authStateUserNameSelector } from './store/selectors/auth';

const MaintenanceModeEnabled = false;

const theme = createTheme({
	palette: {
	  primary: {
		// Purple and green play nicely together.
		main: grey[900],
	  },
	  secondary: {
		// This is green.A700 as hex.
		main: '#36ad78',
	  },
	},
  });

type AppState = {
	navMenuOpened: boolean;
};

const App = ():JSX.Element => {

	const navMenuRef = React.useRef(null);

	const isAuthenticated = useSelector(authStateIsAuthenticated);

	const isAdmin = useSelector(authStateIsCurrentUserAdmin);

	const isMatcher = useSelector(authStateIsCurrentUserMatcher);

	const userAvatarImage = useSelector(authStateUserAvatarSelector);

	const userName        = useSelector(authStateUserNameSelector);

	const [state, setValue] = React.useState({
		navMenuOpened: false
	} as AppState);


	const setState = (update: Partial<AppState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const navigate = useNavigate();

	if (MaintenanceModeEnabled && !localStorage.getItem("MaintenanceMode")) {
		return <div style={{ textAlign: "center" }}>
			<h2>Maintenance Mode</h2>
			<p>we will be back soon</p>
		</div>
	}

	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static">
				<Container maxWidth="xl">
					<Toolbar disableGutters>
					<img src='/favicon/favicon-32x32.png' />
					&nbsp;
					<Typography
						variant="h6"
						noWrap
						component="a"
						href={
							( isAdmin || isMatcher ) ? "/admin" : "/"
						}
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontWeight: 700,
							letterSpacing: '.1rem',
							color: 'inherit',
							textDecoration: 'none',
						}} onClick={(ev) => {
							ev.preventDefault();
							navigate(( isAdmin || isMatcher ) ? "/admin" : "/");
						}}
					>
						CRED DATA MATCHING
					</Typography>


					{
						isAuthenticated && (
							<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar-mobile"
									aria-haspopup="true"
									color="inherit"
									onClick={() => {
										setState({
											navMenuOpened: !state.navMenuOpened
										});
									}}
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar-mobile"
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={state.navMenuOpened}
									onClose={() => {
										console.log('closed');
									}}
									sx={{
										display: { xs: 'block', md: 'none' },
									}}
								>
									<MenuItem key={'p1'}>
										<Typography textAlign="center">1</Typography>
									</MenuItem>
								</Menu>
							</Box>
						)
					}
					<Typography
						variant="h5"
						noWrap
						component="a"
						href=""
						sx={{
						mr: 2,
						display: { xs: 'flex', md: 'none' },
						flexGrow: 1,
						fontFamily: 'monospace',
						fontWeight: 700,
						letterSpacing: '.3rem',
						color: 'inherit',
						textDecoration: 'none',
						}}
						onClick={(ev) => {
							ev.preventDefault();
							navigate('/');
						}}
					>
						CRED
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{ (isAuthenticated && (isAdmin || isMatcher)) ? <Button
								key={'matching'}
								href={'/matching'}
								onClick={(ev) => {
									ev.preventDefault();
									navigate('/matching');
								}}
								sx={{ my: 2, color: 'white', display: 'block' }}
							>
								Matching
							</Button> : '' }
					</Box>
					{
						isAuthenticated && (
							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Open settings">
								<IconButton
									ref={navMenuRef}
									onClick={() => {
										setState({
											navMenuOpened: !state.navMenuOpened
										});
									}}
									sx={{ p: 0 }}
								>
									<Avatar alt={userName} src={userAvatarImage} />
								</IconButton>
								</Tooltip>
								{
									navMenuRef?.current ? <Menu
									sx={{ mt: '45px' }}
									id="menu-appbar"
									anchorEl={navMenuRef?.current}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={state.navMenuOpened}
									onClose={() => {
										console.log('close')
									}}
								>
									<div style={{
										background: "rgba(0, 0, 0, 0.23)",
										position: "fixed",
										top: 0,
										left: 0,
										height: "100%",
										width: "100%",
										zIndex: -1
									}} onClick={() => {
										setState({
											navMenuOpened: false
										});
									}}></div>
									{ (isAdmin || isMatcher) ? <MenuItem key={'admin'} onClick={() => {
										setState({
											navMenuOpened: false
										}, () => {
											navigate('admin');
										});
									}}>
										<Typography textAlign="center">Dashboard</Typography>
									</MenuItem> : '' }
									<MenuItem onClick={() => {
										setState({
											navMenuOpened: false
										}, () => {
											navigate('/auth');
										});
									}}>Profile</MenuItem>
									<Divider sx={{ my: 0.5 }} />
									<MenuItem onClick={() => {
										setState({
											navMenuOpened: false
										});
									}}>Close Menu</MenuItem>
								</Menu> : ''
							}
							</Box>
						)
					}
					</Toolbar>
				</Container>
			</AppBar>
			<Container maxWidth="xl">
				<Outlet />
			</Container>
		</ThemeProvider>
	);
};

export default App;
