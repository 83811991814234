import React, { useEffect } from 'react';
import { Breadcrumbs, Link, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField, Typography, Chip } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import './MatchingDetail.css';
import { authStateCurrentUser, authStateIsCurrentUserAdmin, authStateIsCurrentUserMatcher } from '../../store/selectors/auth';
import DeniedPageComponent from './Denied';

import { useSelector } from 'react-redux';
import { getProgressOfPropritizartionTaskQuery, getProgressOfPropritizartionTaskQueryType, getPropritizartionTasksByUserIdQuery, getPropritizartionTasksType } from '../../graphql/queries/queryPrioritizationTasks';
import { client } from '../../utils/graphQLClient';
import { ConsoleUI } from '../../utils/notification.service';
import { getMatchingDetailsQuery, insertMatchingActivityQuery, insertMatchVariablesSetVariablesType, undoMatching } from '../../graphql/queries/queryMatchingDetails';
import DOMPurify from "dompurify";

import { useNavigate } from 'react-router-dom';

export type MatchingDetailPageComponentState = {
	currentProgress: number;
	comment: string;
	checked_solutions: number[];
	manualSolution: string;
	available_tasks: getPropritizartionTasksType[] | null,
	current_task: getPropritizartionTasksType | null,
	matchingDetails: {
		__typename: "MultipleTableEntryType",
		tableEntryId: string,
		tableName: string
		columns: {
			__typename: "TableEntryColumnEntry",
			name: string,
			value: string
		}[][]
	} | null,
	error_message: string,
	loading: boolean,
};



function MatchingDetailPageComponent(): JSX.Element {

	const isAdmin = useSelector(authStateIsCurrentUserAdmin);
	const currentUser = useSelector(authStateCurrentUser);
	const isMatcher = useSelector(authStateIsCurrentUserMatcher);

	const [state, setValue] = React.useState({
		currentProgress: -1,
		manualSolution: "",
		checked_solutions: [],
		comment: "",
		available_tasks: [],
		current_task: null,
		matchingDetails: null,
		error_message: "",
		loading: true,
	} as MatchingDetailPageComponentState);


	//eslint-disable-next-line
	const setState = (update: Partial<MatchingDetailPageComponentState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser && currentUser.id && !state.available_tasks?.length) {
			ConsoleUI.monitor(
				client.query({ fetchPolicy: 'network-only', query: getPropritizartionTasksByUserIdQuery, variables: {
					start: 0,
					limit: 1E2,
					orderBy: 'ID',
					orderDirection: 'ASC',
					userId: parseInt(currentUser.id, 10)
				} })
			).then(data => {
				const available_tasks: getPropritizartionTasksType[] = data?.data?.tasksByUserId || [];

				if (available_tasks?.length) {
					setState({
						available_tasks,
						loading: false
					})
				} else {
					setState({
						available_tasks: [],
						loading: false
					})
				}
			}, err => setState({ error_message: err.message }));
		}

	}, [currentUser, state.available_tasks, state.loading]);

	useEffect(() => {
		// add listener
		const handler = (ev: KeyboardEvent) => {
			if (
				(
					document.activeElement?.tagName === 'INPUT'
					&&
					(document.activeElement as (Element & { type: string } | null))?.type === 'text'
				) || (
					document.activeElement?.tagName === 'TEXTAREA'
				)
			) return;

			switch (ev.key) {
				case "m":
				case "n":
				case "u":
				case "b":
					// eslint-disable-next-line no-case-declarations
					const button: (Element & { click?: () => void }) | null = document.querySelector(`[data-key-map="${ev.key}"]`);

					if (button && typeof(button.click) === "function") button.click();
				break;
				case "i":
					// eslint-disable-next-line no-case-declarations
					const inputField: (Element & { focus?: () => void }) | null = document.querySelector(`[data-key-map="${ev.key}"] input`);

					if (inputField && typeof(inputField.focus) === "function") {
						inputField.focus();
						ev.preventDefault();
					}
				break;
			}

			console.log({ key: ev.key, ev })
		};
		document.addEventListener("keydown", handler)
        return () => {
			document.removeEventListener("keydown", handler)
        }
    }, [])

	const resetMatchingDetails = () => {
		setState({
			checked_solutions: [],
			matchingDetails: null,
			comment: "",
			manualSolution: "",
			loading: true
		}, () => {
			ConsoleUI.monitor(
				client.query({ fetchPolicy: 'network-only', query: getMatchingDetailsQuery, variables: {
					matching: {
						modelTable: state.current_task?.source.modelTableName,
						filterColumn: state.current_task?.filter.columnName,
						goal: state.current_task?.goal,
						order: state.current_task?.order,
						entityColumnName: state.current_task?.source.modelEntityIdColumnName,
						userId: currentUser?.id
					}
				} })
			).then(({ data: { getMatchingDetails }}) => {
				ConsoleUI.monitor(
					client.query({ fetchPolicy: 'network-only', query: getProgressOfPropritizartionTaskQuery, variables: {
						filterName: state.current_task?.filter.columnName,
						limit: state.current_task?.goal,
						mainTable: state.current_task?.source.entityTable.name,
						order: state.current_task?.order,
						sourceId: state.current_task?.source.entityIdColumnName,
						mainTableColumn: state.current_task?.source.entityIdColumnName,
						sourceTable: state.current_task?.source.modelTableName
					} as getProgressOfPropritizartionTaskQueryType })
				).then((response: { data: { progress: {progress: number }} | null, errors?: unknown}) => {
					setState({
						currentProgress: (response.data || { progress: { progress: -1 } }).progress.progress,
						checked_solutions: [],
						matchingDetails: getMatchingDetails,
						comment: "",
						manualSolution: "",
						loading: false
					});
				});
			});
		})
	}

	useEffect(() => {
		if (!state.current_task || state.matchingDetails || !(currentUser && currentUser.id)) return;
		resetMatchingDetails();
	}, [state.current_task, currentUser]);

	if (state.loading) {
		return <></>;
	}

	if (!isAdmin && !isMatcher) {
		return <DeniedPageComponent />
	}

	if (!state.current_task) {
		return <Box className="MatchingSelectTask">
			{
				!state.available_tasks?.length ? 'You currently have no tasks assigned. Please contact an admin (fernanda@credinvestments.com) to get new tasks.' : <FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Task</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={''}
						label="Choose Task"
						onChange={(
							(event: never, data: { props: { value: string }}) => {
								if (data?.props?.value) {
									setState({ current_task: state.available_tasks ? state.available_tasks.find(item => item.id === data.props.value) : null })
								}
							}
						) as never}
						>
							{
								state.available_tasks.map(task => <MenuItem value={task.id}>{
									`${task.source.name} ( ${task.id}: ${task.users.map(user => user.name).join(", ")} )`
								}</MenuItem>)
							}
					</Select>
			</FormControl>
			}
		</Box>
	}

	if (!state.matchingDetails) return <></>;

	const columns: {
		[columnGroup: string]: {
			[rowName: string]: {
				rowName: string;
				column: {
					__typename: "TableEntryColumnEntry",
					name: string,
					value: string
				}
			}
		}
	} = {};

	const rowNames: string[] = [];
	const columnGroups: string[] = [];
	const columnMetaData: {
		[key: string]: {
			modelDate: string | null,
			probability: string | null,
			entityValue: string | null,
		}
	} = {};


	const touchColumnGroup = (columnGroup: string) => {
		if (columnGroup && !(columnGroup in columns)) {
			columns[columnGroup] = {};
			columnMetaData[columnGroup] = {
				modelDate: null,
				probability: null,
				entityValue: null
			}
		}
	}

	state.matchingDetails.columns.forEach((columnList, columnListIndex) => {
		columnList.forEach((column, _rowIndex) => {
			let columnGroup : string = (
				column.name.match(/_[a-z]/)
				? column.name.replace(/^.*_([a-z])/, '$1')
				: '_'
			);

			if (columnGroup === '_' || columnGroup.length !== 1) {
				if (column.name === "probability") {
					touchColumnGroup(`${columnListIndex}`);
					columnMetaData[`${columnListIndex}`].probability = column.value;
				} else if (column.name === "modelDate") {
					touchColumnGroup(`${columnListIndex}`);
					columnMetaData[`${columnListIndex}`].modelDate = column.value;
				} else {
					return;
				}
			}

			if (columnGroup !== 'a') {
				columnGroup = `${columnListIndex}`;
			}

			if (!columnGroups.includes(columnGroup)) columnGroups.push(columnGroup);

			touchColumnGroup(columnGroup)

			if (columnListIndex && columnGroup === 'a') {
				return;
			}

			const rowName = column.name.replace(/_[^_]*?$/, '');
			if (!rowNames.includes(rowName)) rowNames.push(rowName);
			columns[columnGroup][rowName] = {
				rowName,
				column
			};
		})
	});

	(() => {
		const modelEntityIdColumnName = state.current_task?.source?.modelEntityIdColumnName;
		if (typeof(modelEntityIdColumnName) === "string" && modelEntityIdColumnName.length) {
			columnGroups.forEach(columnGroup => {
				columnMetaData[columnGroup].entityValue = Object.values(
					columns[columnGroup]
				).find(item => item.column.name === modelEntityIdColumnName)?.column?.value || null;
			});
		}
	})();

	columnGroups.sort((a, b) => {
		if (!a.match(/\d/)) return -1;
		if (!b.match(/\d/)) return 1;
		  const ia = parseInt(a, 10);
		const ib = parseInt(b, 10);
		  return ia == ib ? 0 : (ia < ib ? -1 : 1);
	});

	// console.info({
	// 	getMatchingDetails: state.matchingDetails,
	// 	columnGroups,
	// 	rowNames,
	// 	current_task: state.current_task,
	// 	columns,
	// 	columnMetaData
	// })

	const htmlEncode = (value: string): string => {
		return value
			.replace(/&/g, '&')
			.replace(/'/g, "'")
			.replace(/"/g, '"')
			.replace(/>/g, '>')
			.replace(/</g, '<');
	}
	const isValid = (value: string): boolean => {
		if (typeof value !== 'string' || value.trim() === '' || value === 'NaN') {
			return false;
		}
		return true;
	}

	const valueCellRendererWebsites = (value: string, values: string, columnGroup: string): JSX.Element => {
		// Check if the value is a string and not empty or NaN
		if (!isValid(value) || !isValid(values)) {
			return <></>;
		}
		const valuesSet = values.split(/\s*,\s*/);
		const websites = value.split(/\s*,\s*/);
		const isSource = columnGroup === 'a';

		let isMatch = false;
		if (!isSource) {  // Only perform matching for non-source columns
			// Check if each website matches any of the values in valuesSet
			isMatch = websites.every(website =>
				valuesSet.includes(website) || !isValid(website));
		}

		return (
			<>
				{websites.map((website, index) => {
					// Apply the chipStyle only for non-source columns
					const chipStyle = isSource ? '' : (isMatch ? 'green-cell' : 'red-cell');
					return (
						<Chip
							key={index}
							label={website}
							className={chipStyle}
							onClick={() => window.open('//' + website, "")}
						/>
					);
				})}
			</>
		);
	};


	const valueCellRenderer = (value: string, rowName: string, columnGroup: string): JSX.Element => {
		// Remove square brackets if present
		value = value.replace(/\[(.*?)\]/g, "$1").trim();

		// Check if the value is a string and not empty or NaN
		if (!isValid(value)) {
			return <></>;
		}
		// Determine if the current column group is the source
		const isSource = columnGroup === 'a';
		let isMatch = false;
		let cellStyle = '';
		if (isSource) {
			// If this is the source column, no need to compare with other columns
			cellStyle = '';
		} else {
			// Get the value from the source column (column group 'a')
			const sourceValue = columns['a'][rowName]?.column?.value || "";

			// Compare current column's value with the source column's value
			isMatch = isValid(sourceValue) && (value === sourceValue || !isValid(value));
			cellStyle = isMatch ? 'green-cell' : 'red-cell';
		}

		// if rowName is specialities highlight each value
		if (rowName.includes('specialties')) {
			return (
				<>
					{value.split(/\s*,\s*/).map((speciality, index) => {
						const chipStyle = isMatch ? 'green-cell' : 'red-cell';
						return (
							<Chip
								key={index}
								label={speciality}
								className={chipStyle}
							/>
						);
					})}
				</>
			);
		}
		// Format the value as a financial number if the row is related to revenue
		if (rowName.includes('revenue')) {

			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			});

			const formattedValue = formatter.format(parseFloat(value));

			return <div className={cellStyle}>{formattedValue}</div>;
		}

		const html = value.replace(
			/(^|[\W\s])((http|https|ftp|ftps):\/\/[^\n\s]+)/g,
			function (_match, _pattern1, url) {
				const urlEncoded = htmlEncode(url);
				return `${_pattern1}<a href="${htmlEncode(url)}" target="_blank"><img src="${urlEncoded}" class="matching-table-image" /> </a>`
			}
		)
		const data = DOMPurify.sanitize(html, {
			ALLOWED_TAGS: ["h1", "p", "span", "img", "a", "button"],
			ALLOWED_ATTR: ["data-url", "src", "href", "class"]

		}).replace(/(<img[\s\n]+)(src=")([^"]+)/g, function (_str, p1, p2, url) {
			return `${p1} onerror = "
				this.style.display ='none'
			" onload = "
				this.style.display = 'block';
				this.style.height = '';
				this.style.width = '';
			" style="max-width: 100px;${
				url.match(/\.png(\?.*|)$/i) || url.match(/\/image\//i)
				? '' : 'height: 1px; width: 1px; display: inline-block;'
			}" ${p2}${url}`;
		}).replace(/(<a[\s\n]+)(href=")([^"]+)/g, function (_str, p1, p2, url) {
			return `${p1} target="_blank" ${p2}${url}`;
		});
		return <div className={cellStyle} dangerouslySetInnerHTML={{__html: data}} />;
	}

	const insertMatchVariablesSet = (solution: "manual" | "unsure" | "false" | number[]): insertMatchVariablesSetVariablesType[] => {
		const result: insertMatchVariablesSetVariablesType[] = [];

		if (state.current_task) {
			columnGroups
				.filter(columnGroup => columnGroup.match(/^\d+$/))
				.forEach(columnGroup => {
					const columnIndex = parseInt(columnGroup, 10);
					result.push({
						comment: state.comment,
						data: JSON.stringify(state.matchingDetails?.columns[columnIndex]) || "",
						createdByUserId: currentUser?.id || null,
						updatedByUserId: currentUser?.id || null,
						isMatch: (
							solution === "unsure" ? solution : (
								solution === "manual" ? "false" : (
									(Array.isArray(solution) && solution.includes(columnIndex))  ? "true" : "false"
								)
							)
						),
						entityValue: parseInt((`${columnMetaData[columnGroup].entityValue}` || ""), 10) || 0,
						modelDate: columnMetaData[columnGroup].modelDate || new Date().toISOString(),
						probability: parseFloat((`${columnMetaData[columnGroup].probability}` || "")) || 0,
						multiples: (
							(state.matchingDetails?.columns.length || 0) + (solution === "manual" ? 1 : 0)
						),
						sourceId: parseInt((`${state.current_task?.source.id}` || ""), 10) || 0,
						sourceValue: (columns["a"]?.sourceid.column.value || ""),
						tableModelName: state.current_task?.source.modelTableName || "",
						taskId: parseInt((`${state.current_task?.id}` || ""), 10) || 0,
						userId: parseInt((`${currentUser?.id}` || ""), 10) || 0,
					});
				});

			if (solution === "manual") {
				result.push({
					comment: state.comment,
					data: "",
					createdByUserId: currentUser?.id || null,
					updatedByUserId: currentUser?.id || null,
					isMatch: "manual",
					entityValue: parseInt((state.manualSolution || ""), 10) || 0,
					modelDate: new Date().toISOString(),
					probability: 0, // can't be null but maybe it can be
					multiples: (
						(state.matchingDetails?.columns.length || 0) + 1
					),
					sourceId: parseInt((`${state.current_task?.source.id}` || ""), 10) || 0,
					sourceValue: (columns["a"]?.sourceid.column.value || ""),
					tableModelName: state.current_task?.source.modelTableName || "",
					taskId: parseInt((`${state.current_task?.id}` || ""), 10) || 0,
					userId: parseInt((`${currentUser?.id}` || ""), 10) || 0,
				})
			}
			columnGroups.sort((a, b) => {
				//sort by probability
				const probA = parseFloat((`${columnMetaData[a].probability}` || "")) || 0;
				const probB = parseFloat((`${columnMetaData[b].probability}` || "")) || 0;
				return probB - probA;

			}
			);
		}


		return result;
	};

	console.log(insertMatchVariablesSet("manual"));

	const insertMatchAction = (solution: "manual" | "unsure" | "false" | number[]) => {
		if (state.loading) return;

		window.sessionStorage.setItem(
			"evaluations",
			`${(parseInt(window.sessionStorage.getItem("evaluations") || "0", 10) || 0) + 1}`
		);

		const variableSet = insertMatchVariablesSet(solution);

		ConsoleUI.info("sending matching sets");

		setState({
			loading: true
		}, () => {
			ConsoleUI.monitor(
				Promise.all(
					variableSet.map(matching => {
						client.query({ fetchPolicy: 'network-only', query: insertMatchingActivityQuery, variables: {
							matching
						} })
					})
				)
			).then(() => {
				ConsoleUI.info("Changes applied");
				resetMatchingDetails();
			}, () => {
				resetMatchingDetails();
			});
		});

	}

	const undoAction = () => {
		if (!currentUser) return;
		ConsoleUI.info("sending undo match request");

		window.sessionStorage.setItem(
			"evaluations",
			`${Math.max(0, (parseInt(window.sessionStorage.getItem("evaluations") || "0", 10) || 0) - 1)}`
		);

		setState({
			loading: true
		}, () => {
			ConsoleUI.monitor(
				client.query({ fetchPolicy: 'network-only', query: undoMatching, variables: {
					userId: currentUser.id
				} })
			).then(() => {
				ConsoleUI.info("Obtaining Matching details");
				resetMatchingDetails();
			});
		});
	}


	return (
		<Container className="AdminManagment" maxWidth={'xl'} style={
			state.loading ? {
				opacity: "0.6",
				pointerEvents: "none"
			} : {}
		}>
			<br />

			<Typography variant="h5">
				Matching: { state?.matchingDetails?.tableName }
			</Typography>

			<Typography color="GrayText">{
				(parseInt(window.sessionStorage.getItem("evaluations") || "0", 10) || 0)
			} evaluations completed so far this session.</Typography>{
				state.currentProgress >= 0 ? <> current progress: {(state.currentProgress * 100).toFixed(1)}%</> : ''
			}

			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/" onClick={(ev) => {
					ev.preventDefault();
					navigate('/');
				}}>
					Home
				</Link>
				<Link
					underline="hover"
					color="inherit"
					href="admin" onClick={(ev) => {
						ev.preventDefault();
						navigate('/admin');
					}}
				>
					Admin
				</Link>
				<Typography color="text.primary">Matching Details</Typography>
			</Breadcrumbs>

			<br />

			<div className="matching-table-container" >
				<TableContainer className="matching-table" component="table">
					<TableHead>
						<TableRow>
							<TableCell>Field</TableCell>
							{
								columnGroups.map((columnGroup, columnGroupIndex) => {
									return <TableCell title={columnGroup === 'a' ? "" : `probability: ${ ((parseFloat(`${columnMetaData[columnGroup].probability}`) || 0) * 100).toFixed(3) }%`}>
										{
											columnGroup === 'a' ? "Source" : "Target"
										}: {
											columns[columnGroup]['name']?.column?.value || columnGroup
										} {
											columnGroup === 'a' ? "" : <i><sup>
												( option { columnGroupIndex } of { columnGroups.length - 1 } )
											</sup></i>
										}
										{
											columnGroup !== 'a' ? <>
											<br />
											<div>
												{
													(state.matchingDetails?.columns?.length || 0) > 1 ? (
														<>
															<Checkbox color="info" checked={state.checked_solutions.includes(parseInt(columnGroup, 10))} onChange={() => {
																const index = parseInt(columnGroup, 10);
																if (
																	state.checked_solutions.includes(index)
																) {
																	setState({
																		checked_solutions: [
																			...state.checked_solutions
																		].filter(item => item !== index)
																	})
																} else {
																	setState({
																		checked_solutions: [
																			...state.checked_solutions, index
																		]
																	})
																}
															}} /> MATCH
														</>
													) : ''
												}
											</div></> : ""
										}

									</TableCell>
								})
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							rowNames.map((rowName, index) => {
								const values: string[] = columnGroups.filter(columnGroup => {
									return !!columns[columnGroup][rowName];
								});
								const allHaveValues: boolean = values.length === columnGroups.length;
								const allValuesAreSame: boolean = values.map((columnGroup: string) => columns[columnGroup][rowName].column.value)
										.filter((item, index, arr) => index === arr.indexOf(item)).length === 1

								return {
									comparasion: {
										match: allHaveValues && allValuesAreSame,
										noMatch: allHaveValues && !allValuesAreSame,
										haveValues: allHaveValues
									},
									component: <TableRow key={`row_${index}`} className={
										(
											allHaveValues ? ( allValuesAreSame ? 'match' : 'no-match' ) : ""
										)
									}>
										<TableCell>{rowName}</TableCell>
										{
											columnGroups.map((columnGroup, colIndex) => {
												const row = columns[columnGroup][rowName];
												if (row === null || typeof(row) === "undefined" || row.column.value === "NaN") {
													return <TableCell key={`cell_${index}_${colIndex}`} className='null'></TableCell>;
												} else {
													return <TableCell key={`cell_${index}_${colIndex}`}>{
														rowName === 'website' ? valueCellRendererWebsites(
															row.column.value,
															columns['a'][rowName]?.column?.value || "",
															columnGroup,
														) : valueCellRenderer(row.column.value, rowName, columnGroup)
													}</TableCell>;
												}
											})
										}
									</TableRow>
								}
							}).sort((a, b) => {
								if (a.comparasion.match === b.comparasion.match) {
									if (a.comparasion.noMatch === b.comparasion.noMatch) {
										return 0;
									} else if (a.comparasion.noMatch) {
										return -1;
									} else {
										return 1;
									}
								} else if (a.comparasion.match) {
									return -1;
								} else {
									return 1;
								}
							}).map(item => item.component)
						}
					</TableBody>
				</TableContainer>
			</div>

			<footer>
				<br />
				<br />
				<Stack direction="row" spacing={2}>
					<TextareaAutosize
						aria-label="users comment"
						min-rows={3}
						placeholder="Add additional comment ( optional ), comment will be sent a match response"
						style={{ width: "100%" }}
						onChange={(event) => {
							setState({
								comment: event.target.value
							})
						}}
					/>
				</Stack>
				<br />
				<Stack direction="row" spacing={2}>
					<Button variant="contained" className="left" color="primary" onClick={() => {
						undoAction();
					}} data-key-map="b">UNDO AND GO BACK</Button>

					{
						state.matchingDetails?.columns.length === 1
						? <Button
							variant="contained"
							color="success"
							onClick={() => {
								insertMatchAction([0]);
							}} data-key-map="m">MATCH</Button>
						: <Button
							variant="contained"
							color="success"
							disabled={state.checked_solutions.length === 0}
							onClick={() => {
								if (state.checked_solutions.length) {
									insertMatchAction(state.checked_solutions);
								}
							}} data-key-map="m">MATCH ( selected columns )</Button>
					}
					<Button variant="contained" color="error" onClick={() => {
						insertMatchAction("false");
					}} data-key-map="n">NOT MATCH</Button>
					<Button variant="contained" color="inherit" onClick={() => {
						insertMatchAction("unsure");
					}} data-key-map="u">NOT SURE</Button>

					<TextField id="outlined-basic" data-key-map="i" className="right" label="Manual Input" variant="outlined" onChange={(event) => {
						setState({
							manualSolution: event.target.value
						})
					}} onKeyDown={(ev) => {
						if (ev.key === "Enter") {
							insertMatchAction("manual");
						}
					}} />

					<Button variant="contained" className="right" color="info" onClick={() => {
						insertMatchAction("manual");
					}}>SUBMIT MANUAL ID</Button>
				</Stack>
				{
					new Array(3).fill(<br />)
				}
			</footer>
		</Container>
	);
}

export default MatchingDetailPageComponent;
