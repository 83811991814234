import React, { useState } from 'react';
import { Container } from '@mui/system';

import Box from '@mui/material/Box';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';




import { Breadcrumbs, Button, Chip, IconButton, LinearProgress, Link, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import ProgressCircleComponent from '../../atoms/ProgressCircle';


import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { authStateIsAuthenticated } from '../../../store/selectors/auth';
import DeniedPageComponent from '../Denied';

import { useSelector } from 'react-redux';


import { createTaskQuery, createTaskQueryModel, deleteTaskQuery, deleteTaskQueryModel, getProgressOfPropritizartionTaskQuery, getProgressOfPropritizartionTaskQueryType, getPropritizartionTasksQuery, getPropritizartionTasksType, updatePriorityTasksQuery, updatePriorityTasksQueryModel, updateTaskQuery } from '../../../graphql/queries/queryPrioritizationTasks';
import { client } from '../../../utils/graphQLClient';
import { useNavigate } from 'react-router-dom';
import { ConsoleUI } from '../../../utils/notification.service';

import { renderDeleteConfirmDialog } from './DeleteTaskDialog';
import { RenderCreateTaskDialog } from './CreateTaskDialog';

function useForceUpdate(){
    const [value, setValue] = useState(0); // eslint-disable-line
    return () => setValue(value => value + 1); // update state to force render
}

const getColumns = ({
	handleDelete,
	handleUpdate
}: {
	handleDelete: (id: string) => void
	handleUpdate: (id: string, field: string) => void
}): GridColDef[] => {
	return [
		{
			field: 'priorityOrder',
			headerName: 'Priority',
			width: 90,
			type: 'number',
			headerAlign: 'center',
			sortable: false,
			filterable: false,
			align: 'center',
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.priorityOrder !== params.row.initialPriorityOrder) {
					return <div style={{ textAlign: "center" }}>
						{params.row.index}<br /><div style={{ opacity: 0.6, fontSize: '0.9em', textAlign: "center" }}>( from {params.row.initialIndex} )</div>
					</div>;
				} else {
					return <>{params.row.index}</>;
				}
			}
		},
		{
			field: 'source',
			headerName: 'Source',
			minWidth: 180,
			sortable: false,
			// editable: true,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal"}} onClick={
					() => {
						// disable editing of source
						// handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row[params.field]}
				</div>;
			}
		},
		{
			field: 'entity',
			headerName: 'Entity',
			minWidth: 160,
			sortable: false,
			// renderEditCell: (): JSX.Element => {
			// 	return <Autocomplete
			// 		disablePortal
			// 		options={companies}
			// 		sx={{ width: 300 }}
			// 		renderInput={(params) => <TextField {...params} label="Company" />}
			// 	/>;
			// },
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal"}} title={params.row.entityColumn} onClick={
					() => {
						// disable editing of entity
						// handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row[params.field]}
				</div>;
			}
			// editable: true
		},
		{
			field: 'sorting_column',
			headerName: 'Sorting Column',
			// editable: true,
			sortable: false,
			type: 'string',
			align: 'left',
			headerAlign: 'left',
			minWidth: 120,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal", width: "100%", cursor: "pointer"}} onClick={
					() => {
						handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row[params.field]}
				</div>;
			}
		},
		{
			field: 'sorting_order',
			headerName: 'Sorting Order',
			// editable: true,
			sortable: false,
			// renderEditCell: (params): JSX.Element => {
			// 	return <Autocomplete
			// 		disablePortal
			// 		options={[
			// 			{
			// 				id: 'ASC',
			// 				label: 'ASC'
			// 			},
			// 			{
			// 				id: 'DESC',
			// 				label: 'DESC'
			// 			}
			// 		]}
			// 		sx={{ width: 300 }}
			// 		onChange={(event, data, eventType) => {
			// 			if (eventType === "selectOption" && data) {
			// 				params.row[params.field] = data?.id;
			// 			}
			// 		}}
			// 		value={{ id: params.value, label: params.value }}
			// 		renderInput={(params) => <TextField {...params} label="Order" />}
			// 	/>;
			// },
			type: 'string',
			align: 'left',
			headerAlign: 'left',
			minWidth: 120,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal", width: "100%", cursor: "pointer"}} onClick={
					() => {
						handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row[params.field]}
				</div>;
			}
		},
		{
			field: 'goal',
			headerName: 'Goal',
			sortable: false,
			align: "right",
			width: 120,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal", width: "100%", cursor: "pointer"}} onClick={
					() => {
						handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row[params.field]}
				</div>;
			}
		},

		{
			field: 'progress',
			headerName: 'Progress',
			sortable: false,
			disableExport: true,
			filterable: false,
			hideable: false,
			pinnable: true,
			width: 90,
			align: 'center',
			headerAlign: 'center',
			renderCell : (params: GridRenderCellParams) => {
				if (params.row.progress === -2) {
					return <>?</>
				}
				const available = params.row.progress >= 0;
				return <>
					<ProgressCircleComponent variant={available ? "determinate" : "indeterminate"} value={available ? params.row.progress : undefined} showProcent={available} />
				</>;
			},
		},
		{
			field: 'doneCount',
			headerName: 'Tasks Done',
			sortable: false,
			width: 120,
			align: 'center',
			headerAlign: 'center',
			renderCell : (params: GridRenderCellParams) => {
				const progress = params.row.progress || 0;
				const goal = params.row.goal || 0;
				const doneCount = Math.round(progress * goal);
				return <div style={{ textAlign: "center" }}>{doneCount}</div>;

			}
		},
		{
			field: 'assigned_matchers',
			headerName: 'Assigned Matchers',
			sortable: false,
			minWidth: 230,
			flex: 1,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal", width: "100%", cursor: "pointer"}} onClick={
					() => {
						handleUpdate(params.row.id, params.field);
					}
				}>
					{
						params.row.assigned_matchers.length ?
						params.row.assigned_matchers.map(
							(user: { id: number, name: string, email: string }, _index: number) => <>
							<Chip key={user.id} label={user.name} title={user.email} style={{cursor: "pointer"}} />
						</>) : (
							<i>click to assign users</i>
						)
					}
				</div>;
			},
		},
		{
			field: 'source.automatchingThreshold',
			headerName: 'Automatching Threshold',
			minWidth: 90,
			sortable: false,
			filterable: false,
			// editable: true,
			renderCell : (params: GridRenderCellParams) => {
				return <div style={{whiteSpace: "normal"}} onClick={
					() => {
						// disable editing of source
						// handleUpdate(params.row.id, params.field);
					}
				}>
					{params.row.source?.automatchingThreshold}
				</div>;
			}
		},
		{
			field: 'actions',
			headerName: 'Actions',
			sortable: false,
			disableExport: true,
			filterable: false,
			hideable: false,
			pinnable: true,
			width: 120,
			align: 'right',
			headerAlign: 'left',
			renderCell : (params: GridRenderCellParams) => {
				return <>
					<IconButton color="error" component="button" onClick={() => {
						handleDelete(params.row.id);
					}}>
						<DeleteIcon />
					</IconButton>
				</>;
			},
		},
	];
}

const demo_rows: AdminManagementDataRow[] = []
	// {
	// 	id: 1,
	// 	source: 'Pathmatics',
	// 	entity: 'Company',
	// 	sorting_column: 'Total spend',
	// 	sorting_order: 'DESC',
	// 	goal: 'Top 10K',
	// 	progress: 0.45,
	// 	assigned_matchers: [

	// 	]
	// },


function sleep(duration: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

type AdminManagementDataRow = {
	id: string;
	index: number;
	initialIndex: number;
	source: string;
	sourceId: string;
	filterId: string;
	entity: string;
	entityColumn: string;
	sorting_column: string;
	sorting_order: string;
	goal: number;
	initialPriorityOrder: number;
	priorityOrder: number;
	progress: number;
	assigned_matchers: {
		id: string;
		email: string;
		name: string;
	}[]
}

export type AdminManagementPageComponentProps = {
	rows?: AdminManagementDataRow[]
};

export type AdminManagementPageComponentState = {
	rows: AdminManagementDataRow[],
	loading: boolean,
	selectedTask: string,
	showCreateForm: boolean,
	showDeleteConfirm: boolean,
	editFields: string[] | null
};


let lastSetState: {
	state: AdminManagementPageComponentState, setState: (update: Partial<AdminManagementPageComponentState>, cb?: () => void) => void
} | null = null

const setProgress = (item: getPropritizartionTasksType, progress: number): boolean => {
	const state: AdminManagementPageComponentState | null = lastSetState?.state || null;
	const setState: (update: Partial<AdminManagementPageComponentState>, cb?: () => void) => void = (update, cb) => {
		if (lastSetState) lastSetState.setState(update, cb);
	}

	if (!state) return false;

	let updated = false;

	state.rows = state.rows.map(rowItem => {
		if (rowItem.id === item.id) {
			rowItem.progress = progress;
			updated = true;
		}

		return rowItem;
	})

	if (updated) {
		setState({ rows: state.rows });
	}

	return updated;
}

const getProgress = (item: getPropritizartionTasksType): number => {

	if (item.source) {
		ConsoleUI.monitor(
			client.query({ fetchPolicy: 'network-only', query: getProgressOfPropritizartionTaskQuery, variables: {
				filterName: item.filter.columnName,
				limit: item.goal,
				mainTable: item.source.entityTable.name,
				order: item.order,
				sourceId: item.source.entityIdColumnName,
				mainTableColumn: item.source.entityIdColumnName,
				sourceTable: item.source.modelTableName
			} as getProgressOfPropritizartionTaskQueryType })
		).then((progresData: { data: { progress: {progress: number }} | null, errors?: unknown}) => {
			if (progresData.errors && progresData.data === null) {
				setProgress(item, -2);
			}

			setProgress(item, parseFloat(`${progresData?.data?.progress?.progress}`));
		}).catch((err: Error) => {
			console.error(err);
			setProgress(item, -2);
		});
	} else {
		return -2;
	}

	return -1;
}

const AdminManagementPageComponent = (props: AdminManagementPageComponentProps):JSX.Element => {

	const isAuthenticated = useSelector(authStateIsAuthenticated);

	if (!isAuthenticated) {
		return <DeniedPageComponent />
	}

	const [state, setValue] = React.useState({
		rows: [
			...(props.rows || []),
			...demo_rows
		],
		loading: false,
		showCreateForm: false,
		showDeleteConfirm: false,
		selectedTask: "",
		editFields: null,
		task: null,
	} as AdminManagementPageComponentState);


	const setState = (update: Partial<AdminManagementPageComponentState>, cb?: () => void) => {
		setValue({ ...state, ...update });
		if (cb) cb();
	}

	lastSetState = {
		state,
		setState
	};

	const loadServerRows = async (newRowLength: number, clearCache?: boolean, stateUpdate?: Partial<AdminManagementPageComponentState>) => {
		setState({ ...(stateUpdate || {}), loading: true });

		const data = await ConsoleUI.monitor(client.query({ fetchPolicy: 'network-only', query: getPropritizartionTasksQuery, variables: {
			start: clearCache ? 0 : state.rows.length,
			// plus 200 is required BE logic on tasks query prioritization
			limit: newRowLength + 200,
			orderBy: 'ID',
			orderDirection: 'ASC'
		} }));

		const tasks: { node: getPropritizartionTasksType }[] = data?.data?.tasksConnection?.edges || [];

		setState({ ...(stateUpdate || {}), loading: false });

		sleep(100).then(
			() => {

				setState({
					...(stateUpdate || {}),
					loading: false,
					rows: [
						...(clearCache ? [] : state.rows ),
						...(
							tasks.filter(({node}) => {
								return clearCache || !state.rows.find(row => row.id === node.id);
							}).map(
								({node}, index) => {
									return {
										id: node.id,
										index: ( clearCache ? 0 : state.rows.length ) + index + 1,
										initialIndex: ( clearCache ? 0 : state.rows.length ) + index + 1,
										source: node?.source?.name,
										sourceId: node?.source?.id,
										filterId: node?.filter?.id,
										entity: node?.source?.entityTable?.name || node?.source?.entityIdColumnName,
										entityColumn: node?.source?.entityIdColumnName,
										sorting_column: node?.filter?.clause,
										sorting_order: node.order,
										priorityOrder: node.priorityOrder,
										initialPriorityOrder: node.priorityOrder,
										goal: node.goal,
										progress: getProgress(node),
										assigned_matchers: node.users
									};
								}
							) as AdminManagementDataRow[]
						)
					].sort((a, b) => {
						const an = parseInt(`${a.priorityOrder}`, 10) || 0;
						const bn = parseInt(`${b.priorityOrder}`, 10) || 0;
						if (an === bn) return 0;

						return an > bn ? 1 : -1;
					})
				});
			}
		).catch(console.error);


	};

	const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = (params) => {
		if (state.rows.length <= 2000) {
			loadServerRows(params.viewportPageSize * 2);
		}
	};

	const { rows, loading } = state;

	const navigate = useNavigate();

	return (
		<Container className="AdminManagment" maxWidth={'xl'}>

			{renderDeleteConfirmDialog({
				open: state.showDeleteConfirm,
				handleCancel: () => {
					setState({ showDeleteConfirm: false });
				},
				handleOk: () => {
					ConsoleUI.log("Removing task..")
					ConsoleUI.monitor(
						client.mutate({ fetchPolicy: 'network-only', mutation: deleteTaskQuery, variables: {
							taskId: parseInt(state.selectedTask, 10) || 0
						} as deleteTaskQueryModel }),
						"Task Removed"
					).then(() => {
						setState({
							selectedTask: "",
							rows: rows.filter(row => row.id != state.selectedTask),
							showDeleteConfirm: false
						});
					});
				},
				handleClose: () => {
					setState({ showDeleteConfirm: false });
				}
			})}

			{<RenderCreateTaskDialog {...{
				open: state.showCreateForm,
				editFields: state.editFields,
				skipSourceIds: state.rows.map(row => row.sourceId).filter((item, index, arr) => (index === arr.indexOf(item))),
				task: state.rows.filter(row => row.id === state.selectedTask).map(row => ({
					id: parseInt(row.id, 10) || 0,
					sourceId: parseInt(row.sourceId, 10) || 0,
					source: row.source || "",
					filterId: parseInt(row.filterId, 10) || 0,
					endDate: new Date(),
					goal: row.goal,
					order: row.sorting_order,
					sorting_column: row.sorting_column,
					priorityOrder: row.priorityOrder,
					startDate: new Date(),
					users: row.assigned_matchers.map(matcher => ({ id: parseInt(matcher.id, 10) || 0 }))
				} as createTaskQueryModel))[0] || {
					id: 0,
					endDate: new Date(),
					startDate: new Date(),
					priorityOrder: 0,
					filterId: 0,
					goal: 0,
					order: "",
					sorting_column: "",
					sourceId: 0,
					users: []
				},
				handleCancel: () => {
					setState({ showCreateForm: false });
				},
				handleClose: () => {
					setState({ showCreateForm: false });
				},
				handleOk: (task: createTaskQueryModel) => {
					if (task.id) {
						ConsoleUI.monitor(
							client.mutate({ fetchPolicy: 'network-only', mutation: updateTaskQuery, variables: {
								taskId: task.id,
								task: Object.assign(
									task,
									{
										id: undefined,
										source: undefined,
										priorityOrder: undefined,
										sorting_column: undefined
									}
								)
							}}),
							"Task was updated"
						).then(() => {
							const length = state.rows.length;
							loadServerRows(length, true, { showCreateForm: false });
						});
					} else {
						ConsoleUI.monitor(
							client.mutate({ fetchPolicy: 'network-only', mutation: createTaskQuery, variables: {
								task: Object.assign(task, { sorting_column: undefined })
							}}),
							"Task was added"
						).then(() => {
							const length = state.rows.length;
							setState({ loading: true, showCreateForm: false }, () => {
								loadServerRows(length, true, { showCreateForm: false });
							})
						});
					}
					setState({ showCreateForm: false });
				},
			}} />}

			<br />

			<Typography variant="h5">Admin: Task Prioritisation</Typography>

			<Box sx={{ width: '100%' }}  style={{ position: 'relative' }}>
				<Breadcrumbs aria-label="breadcrumb">
					<Link underline="hover" color="inherit" href="/" onClick={(ev) => {
						ev.preventDefault();
						navigate('/');
					}}>
						Home
					</Link>
					<Link
						underline="hover"
						color="inherit"
						href="admin" onClick={(ev) => {
							ev.preventDefault();
							navigate('/admin');
						}}
					>
						Admin
					</Link>
					<Typography color="text.primary">Tasks</Typography>
				</Breadcrumbs>
				<Button
					variant='contained'
					disabled={loading}
					style={{ position: 'absolute', right: 0, top: 0 }}
					onClick={() => {
						setState({
							showCreateForm: true,
							editFields: null,
							selectedTask: "",
						})
					}}>Add new Task</Button>
				{
					rows.find(row => row.index !== row.initialIndex) ? (
						<Button
							variant='contained'
							color='warning'
							disabled={loading}
							onClick={() => {
								const length = state.rows.length;
								ConsoleUI.monitor(
									client.mutate({ fetchPolicy: 'network-only', mutation: updatePriorityTasksQuery, variables: {
										tasks: rows.map(row => ({
											id: parseInt(row.id, 10) || 0,
											priorityOrder: row.priorityOrder,
										})) as updatePriorityTasksQueryModel[]
									} })
								).then(() => {
									loadServerRows(length, true, { showCreateForm: false });
								})
							}}
							style={
								{
									position: 'absolute', right: '145px', top: 0
								}
							}
						>Update Tasks' Order</Button>
					) : ''
				}
			</Box>

			<br />

			<Box sx={{ height: 'calc( 100vh - 200px )', width: '100%' }}>
				<DataGridPro
					rowReordering
					rows={rows}
					columns={getColumns({
						handleUpdate: (selectedTask: string, field: string | null) => {
							setState({
								selectedTask,
								showCreateForm: true,
								editFields: field ? [field] : null
							}, () => {
								useForceUpdate();
							})
						},
						handleDelete: (selectedTask: string) => {
							setState({
								selectedTask,
								showDeleteConfirm: true
							})
						}
					})}
					loading={loading}
					disableSelectionOnClick
					editMode='cell'
					hideFooterPagination
					onRowsScrollEnd={handleOnRowsScrollEnd}
					components={{
						LoadingOverlay: LinearProgress,
					}}
					getRowClassName={(params) => {
						return params.row.initialIndex !== params.row.index ? 'row-changed' : '';
					}}
					onRowOrderChange={(params) => {
						let newRows = [...state.rows]

						const order = state.rows[params.targetIndex].priorityOrder;
						state.rows[params.targetIndex].priorityOrder = state.rows[params.oldIndex].priorityOrder;
						state.rows[params.oldIndex].priorityOrder = order;

						newRows.sort((a, b) => {
							const an = parseInt(`${a.priorityOrder}`, 10) || 0;
							const bn = parseInt(`${b.priorityOrder}`, 10) || 0;
							if (an === bn) return 0;

							return an > bn ? 1 : -1;
						});

						newRows = newRows.map((row, index) => {
							row.index = index + 1;
							return row;
						});

						setState({ rows: newRows });
					}}
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
		</Container>
	);
}

export default AdminManagementPageComponent;
